<div class="card card-bordered bg-light" style="width: 300px;" *ngIf="show">
    <div class="card-header">
        <h4 class="card-title"><strong>Teilnehmer</strong></h4>
    </div>
    <div class="ps-container ps-theme-default ps-active-y"style="overflow-y: scroll !important; height: 100%;">
        <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let user of participants">
                {{user.firstName}} {{user.lastName}}
                <div>
                    <fa-icon [styles]="{'margin-left': '5px'}" [size]="'lg'" [icon]="['fas', 'hand-paper']" *ngIf="user.state.handRaised"></fa-icon>
                    <fa-icon [styles]="{'margin-left': '5px'}" [size]="'lg'" [icon]="['fas', 'video']" *ngIf="user.state.videoEnabled"></fa-icon>
                    <fa-icon [styles]="{'margin-left': '5px'}" [size]="'lg'" [icon]="['fas', 'video-slash']" *ngIf="!user.state.videoEnabled"></fa-icon>
                    <fa-icon [styles]="{'margin-left': '5px'}" [size]="'lg'" [icon]="['fas', 'microphone']" *ngIf="user.state.micEnabled"></fa-icon>
                    <fa-icon [styles]="{'margin-left': '5px'}" [size]="'lg'" [icon]="['fas', 'microphone-slash']" *ngIf="!user.state.micEnabled"></fa-icon>
                </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="participants.length < 1">
                Keine Teilnehmer vorhanden
            </li>
        </ul>
        <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px;">
            <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
        </div>
        <div class="ps-scrollbar-y-rail" style="top: 0px; height: 0px; right: 2px;">
            <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 2px;"></div>
        </div>
    </div>
</div>
