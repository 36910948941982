import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Affirmation, Lecture, NewLecture, UpdateLecture } from './interfaces';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbTime } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class LectureService {

    allLectureObservable: Observable<Lecture[]>;

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Lecture[]>('/api/lecture');
    }

    getRegistrations() {
        return this.http.get<Lecture[]>('/api/lecture/own');
    }

    getById(id) {
        return this.http.get<Lecture>('/api/lecture/' + id);
    }

    create(lecture: NewLecture) {
        return this.http.post<Lecture>('/api/lecture/create', lecture);
    }

    update(lecture: UpdateLecture) {
        return this.http.post<Lecture>('/api/lecture/update', lecture);
    }

    removeRegistration(lectureId: string, userId: string) {
        return this.http.post<Lecture>('/api/lecture/unregister', { lectureId, userId });
    }


    checkIfLectureIsOpen(id) {
        return this.http.get<boolean>('/api/lecture/open/' + id);
    }

    register(lectureId: string, userId?: any) {
        if (!userId) {
            userId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : false;
            if (!userId) {
                return throwError('No userId provided');
            }
            userId = userId.userId;
            console.log({ userId });
        }
        return this.http.post('/api/lecture/register', { lectureId, userId });
    }

    convertTpToString(input: NgbTime): string {
        return `${input.hour > 9 ? input.hour : '0' + input.hour}:${input.minute > 9 ? input.minute : '0' + input.minute}`;
    }

    convertDpToMoment(input: NgbDate): moment.Moment {
        return moment(`${input.year}-${input.month}-${input.day}`, 'YYYY-M-D');
    }

    convertMomentToDp(input: moment.Moment): NgbDateStruct {
        return { year: input.year(), month: input.month(), day: input.date() };
    }

    getAttendaceReport(lectureId: string) {
        return this.http.get<Affirmation[]>('/api/attendance/' + lectureId);
    }
}
