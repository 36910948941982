<form class="form-signin" [formGroup]="loginForm">
  <h1 class="h3 mb-3 font-weight-normal">Bitte anmelden</h1>
  <label for="inputEmail" class="sr-only">Email Adnresse</label>
  <input type="email" id="inputEmail" name="mail" class="form-control" placeholder="Email address" required autofocus formControlName="mail">
  <label for="inputPassword" class="sr-only">Passwort</label>
  <input type="password" id="inputPassword" name="password" class="form-control" placeholder="Password" required formControlName="password">
  <div class="alert alert-danger mt-1" role="alert" *ngIf="loginError">{{loginError}}</div>
  <button class="btn btn-lg btn-primary btn-block" (click)="submitLogin()">Anmelden</button>
  <hr>
  <a class="btn-link" routerLink="/login/register">Noch kein Profil? Hier Registrieren</a>
</form>
