import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheUser, RegistrationUser } from './interfaces';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) { }

  public isAuthenticated(): boolean {
    const userData = localStorage.getItem('userInfo');
    if (userData && JSON.parse(userData)){
      return true;
    }
    return false;
  }

  public getCurrentUser(): CacheUser | boolean {
    return localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : false;
  }

  public getCurrentUserSecure() {
    return this.http.get('/api/me');
  }

  public setUserInfo(user) {
    localStorage.setItem('userInfo', JSON.stringify(user));
  }

  public logOut() {
    localStorage.removeItem('userInfo');
    location.pathname = '/logout';
  }

  public validate(mail: string, pw: string) {
    return this.http.post('/login', {
      mail,
      password: pw
    });
  }

  public register(user: RegistrationUser) {
    return this.http.post('/api/user/register', user);
  }
}
