import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { AvatarModule } from 'ngx-avatar';
import { ToastrModule } from 'ngx-toastr';
import { AngularDraggableModule } from 'angular2-draggable';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { QuillModule } from 'ngx-quill';
import { PortalModule } from '@angular/cdk/portal';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ProfilComponent } from './profil/profil.component';
import { AdminCalendarComponent } from './admin-calendar/admin-calendar.component';
import { UserListComponent } from './user-list/user-list.component';
import { AdminsMessagesComponent } from './admins-messages/admins-messages.component';

import { LectureComponent } from './lecture/lecture.component';
import { LectureChatComponent } from './lecture-chat/lecture-chat.component';
import { LectureParticipantListComponent } from './lecture-participant-list/lecture-participant-list.component';
import { LectureParticipantVideoComponent } from './lecture-participant-video/lecture-participant-video.component';
import { LectureVideoGridComponent } from './lecture-video-grid/lecture-video-grid.component';
import { LectureAudioComponent } from './lecture-audio/lecture-audio.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faEdit, faMicrophone, faMicrophoneSlash, faComment, faCommentSlash, faPhoneSlash, faPaperPlane,
    faHandPaper as solidHand, faVideo, faVideoSlash, faUsers, faUsersSlash, faDesktop, faCamera, faSmile,
    faThLarge, faWindowMaximize, faWindowMinimize, faInfoCircle, faUserCheck, faCheck, faCross,
    faTimes, faPhone
} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faHandPaper as outlineHand } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { CoreModule } from './core/core.module';

registerLocaleData(localeDe, 'de-De');

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CalendarComponent,
        AdminComponent,
        LoginComponent,
        RegistrationComponent,
        ProfilComponent,
        AdminCalendarComponent,
        UserListComponent,
        AdminsMessagesComponent,
        LectureComponent,
        LectureChatComponent,
        LectureParticipantListComponent,
        LectureParticipantVideoComponent,
        LectureVideoGridComponent,
        LectureAudioComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        QuillModule.forRoot(),
        SweetAlert2Module.forRoot(),
        BrowserAnimationsModule,
        PortalModule,
        DpDatePickerModule,
        NgbModule,
        AngularDraggableModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot({
            preventDuplicates: true
        }),
        FontAwesomeModule,
        AvatarModule,
        NgxSpinnerModule,
        CoreModule
    ],
    providers: [],
    bootstrap: [AppComponent],
    entryComponents: [LectureVideoGridComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(icons: FaIconLibrary) {
        icons.addIcons(faEdit, faMicrophone, faMicrophoneSlash, faComment, faCommentSlash, faPaperPlane,
            faPhoneSlash, faTrashAlt, solidHand, outlineHand, faVideo, faVideoSlash, faUsers, faUsersSlash,
            faDesktop, faCamera, faSmile, faThLarge, faWindowMaximize, faWindowMinimize, faInfoCircle,
            faUserCheck, faCheck, faCross, faTimes, faPhone);
    }
}
