import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';

export const confirmPasswordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const pw = control.get('password');
  const pw2 = control.get('passwordConfirm');

  return pw && pw2 && pw.value === pw2.value ? null : { pwMissmatch: true };
};

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  registrationForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    mail: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    passwordConfirm: ['', [Validators.required, Validators.minLength(6)]],
  }, {validators: [confirmPasswordValidator]});

  showErrorMsg = false;
  showSuccessMsg = false;
  errorMsg = '';

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.registrationForm.valueChanges.subscribe((data) => {
      console.log(this.registrationForm);
    });
  }

  submitRegistration() {
    this.authService.register(this.registrationForm.value).subscribe((data: any) => {
      if (data._id) {
        this.showSuccessMsg = true;
        this.registrationForm.disable();
      } else {
        this.errorMsg = 'Da ist etwas schief gelaufen!';
        this.showErrorMsg = true;
      }
    }, (err) => {
      this.errorMsg = err.error.error;
      this.showErrorMsg = true;
      console.log(err);
    });
  }

  goToLogin() {
    let confirmed = true;
    if (this.registrationForm.dirty && !this.registrationForm.disabled) {
      confirmed = confirm('Seite wirklich verlassen? Alle Änderungen gehen verloren!');
    }
    if (confirmed) {
      this.router.navigate(['/login']);
    }
  }

}
