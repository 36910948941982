import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { CacheUser, ChatMessage } from '../shared/interfaces';
import { LectureMeetingService } from '../shared/lecture-meeting.service';
import { LectureWsService } from '../shared/lecture-ws.service';
import { UserService } from '../shared/user.service';

@Component({
		selector: 'app-lecture-chat',
		templateUrl: './lecture-chat.component.html',
		styleUrls: ['./lecture-chat.component.scss']
})
export class LectureChatComponent implements OnInit, OnDestroy, AfterViewChecked {

    @Input()  show: boolean;
    @Output() showChange = new EventEmitter<boolean>();
    @ViewChild('chatScrollContainer') chatScrollContainer: ElementRef;

    public messages: ChatMessage[] = [];
    public ownUserId: string;
    public msgInput: string = "";

    private msgSubscription: Subscription;
    private allMsgSubscription: Subscription;
    private disableScrollDown: boolean = false;

	constructor(private authService: AuthService, private meetingService: LectureMeetingService, private toastr: ToastrService, private userService: UserService) {}

	ngOnInit(): void {
        this.ownUserId = (this.authService.getCurrentUser() as CacheUser).userId;
        this.msgSubscription = this.meetingService.messagesObservable$.subscribe((msg: ChatMessage[]) => {
            if(!this.show) {
                this.toastr.info("Neue Nachricht!");
            }
        });

        this.allMsgSubscription = this.meetingService.allMessagesObservable$.subscribe((msg: ChatMessage[]) => {
            this.messages = msg;
        });
    }

    ngOnDestroy() {
        this.msgSubscription.unsubscribe();
        this.allMsgSubscription.unsubscribe();
    }

    ngAfterViewChecked() {
        // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        this.scrollToBottom();
    }

	toggleVisibility() {
        this.show = !this.show;
		this.showChange.emit(this.show);
    }

    submitMsg() {
        this.meetingService.sendMessage(this.msgInput);
        this.msgInput = "";
    }

    onScroll() {
        let element = this.chatScrollContainer.nativeElement
        let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight
        if (this.chatScrollContainer && atBottom) {
            this.disableScrollDown = false
        } else {
            this.disableScrollDown = true
        }
    }

    scrollToBottom() {
        if(this.disableScrollDown === false) {
            try {
                this.chatScrollContainer.nativeElement.scrollTop = this.chatScrollContainer.nativeElement.scrollHeight;
            } catch(err) { } 
        }
    }

}
