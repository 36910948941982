<nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="/">
    <img src="assets/img/logo.gif" alt="Logo" height="40">
    Unterrichtplattform
  </a>
  <button class="navbar-toggler" type="button" (click)="showMobileMenu = !showMobileMenu;" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngClass]="{'show': showMobileMenu}" id="navbarSupportedContent">
    <div class="navbar-nav mr-auto">
      <div class="nav-item" *ngIf="currentUser && !currentUser.admin">
        <a class="nav-link" routerLink="/" routerLinkActive="active" (click)="showMobileMenu = false;">Übersicht</a>
      </div>
      <div class="nav-item" *ngIf="currentUser && currentUser.admin">
        <a class="nav-link" routerLink="/admin" routerLinkActive="active" (click)="showMobileMenu = false;">Termine</a>
      </div>
      <div class="nav-item" *ngIf="currentUser && currentUser.admin">
        <a class="nav-link" routerLink="/admin/user" routerLinkActive="active" (click)="showMobileMenu = false;">Benutzer</a>
      </div>
      <div class="nav-item" *ngIf="currentUser && currentUser.admin">
        <a class="nav-link" routerLink="/admin/messages" routerLinkActive="active" (click)="showMobileMenu = false;">Benachrichtigungen</a>
      </div>
      <div class="nav-item" *ngIf="!currentUser">
        <a class="nav-link" routerLink="/login/register" routerLinkActive="active" (click)="showMobileMenu = false;">Registrieren</a>
      </div>
    </div>
    <div class="navbar-nav ml-auto">
      <div class="nav-item" *ngIf="currentUser">
        <a class="nav-link" routerLink="/profil" routerLinkActive="active">Profil</a>
      </div>
      <div class="nav-item" *ngIf="!currentUser">
        <a class="nav-link" routerLink="/login" routerLinkActive="active" (click)="showMobileMenu = false;"><b>Login</b></a>
      </div>
      <div class="nav-item" *ngIf="currentUser">
        <a class="nav-link" style="cursor: pointer;" (click)="logOut()" routerLinkActive="active" (click)="showMobileMenu = false;"><b>Abmelden</b></a>
      </div>
    </div>
  </div>
</nav>

<div [ngClass]="{'container': !lectureDesign, 'default-container': !lectureDesign, 'lecture-container': lectureDesign}">
  <router-outlet></router-outlet>
</div>

<nav class="navbar fixed-bottom navbar-light bg-light">
  &copy; 2021 Die Fahrschule! Albrecht &amp; Brüderlin
</nav>
