<h3>Unterrichts Verwaltung</h3>
<ul class="nav nav-tabs nav-justified">
  <li class="nav-item">
    <a class="nav-link" style="cursor: pointer;" [ngClass]="{'active': tabs.future}" (click)="changeTab('future')">Zukünftige Termine</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" style="cursor: pointer;" [ngClass]="{'active': tabs.past}" (click)="changeTab('past')">Vergangene Termine</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" style="cursor: pointer;" [ngClass]="{'active': tabs.all}" (click)="changeTab('all')">Alle Termine</a>
  </li>
</ul>

<div class="tab-content">
  <div class="tab-pane fade show active" id="future" role="tabpanel" *ngIf="tabs.future">
    <br>
    <app-admin-calendar [data]="'future'"></app-admin-calendar>
  </div>
  <div class="tab-pane fade show active" id="past" role="tabpanel" *ngIf="tabs.past">
    <br>
    <app-admin-calendar [data]="'past'"></app-admin-calendar>
  </div>
  <div class="tab-pane fade show active" id="all" role="tabpanel" *ngIf="tabs.all">
    <br>
    <app-admin-calendar [data]="'all'"></app-admin-calendar>
  </div>
</div>
