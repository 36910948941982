<h3>Hi {{userName}}</h3>
<div class="alert alert-danger alert-dismissible" role="alert" *ngFor="let msg of messages;">
  <h6 class="alet-heading">
    {{msg.title}} <span class="badge badge-light float-right">{{msg.created_at | date}}</span>
  </h6>
  <div [innerHTML]="msg.content"></div>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert($event)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<ul class="nav nav-tabs nav-justified">
  <li class="nav-item">
    <a class="nav-link" style="cursor: pointer;" [ngClass]="{'active': tabs.overview}"
      (click)="changeTab('overview')">Alle Termine</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" style="cursor: pointer;" [ngClass]="{'active': tabs.mine}" (click)="changeTab('mine')">Meine
      Anmeldungen</a>
  </li>
</ul>

<div class="tab-content">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf="tabs.overview">
    <app-calendar></app-calendar>
  </div>
  <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab" *ngIf="tabs.mine">
    <app-calendar [registrationsOnly]="true"></app-calendar>
  </div>
</div>
