import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  userError = false;
  userErrorMsg = '';
  userSuccess = false;
  userSuccessMsg = 'Benutzerdaten erfolgreich upgedated.';
  pwError = false;
  pwErrorMsg = '';
  pwSuccess = false;
  pwSuccessMsg = 'Passwort erfolgreich upgedated.';

  user: {
    firstName: string,
    lastName: string,
    mail: string
  };

  userForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    mail: ['', Validators.required]
  });

  passwordForm = this.fb.group({
    newPassword: ['', [Validators.required]],
    newPasswordConfirm: ['', [Validators.required]],
    currentPassword: ['', [Validators.required]]
  });

  constructor(private fb: FormBuilder, private userService: UserService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.userService.getDetails().subscribe((data: any) => {
      this.user = {... data};
      this.userForm = this.fb.group({
        firstName: [this.user?.firstName, Validators.required],
        lastName: [this.user?.lastName, Validators.required],
        mail: [this.user?.mail, Validators.required]
      });
    }, err => {
      console.log(err);
    })
  }

  saveProfile() {
    this.userService.update(this.userForm.value).subscribe((data: any) => {
      this.userSuccess = true;
      setTimeout(() => { this.userSuccess = false; }, 5000);
      this.loadData();
    }, (err) => {
      this.loadData();
      this.userSuccess = true;
      setTimeout(() => { this.userSuccess = false; }, 5000);
      // this.userError = true;
      // this.userErrorMsg = err.error.error;
      console.log(err);
    });
  }

  savePassword() {
    this.userService.updatePassword(this.passwordForm.value).subscribe((data: any) => {
      this.pwSuccess = true;
      setTimeout(() => { this.pwSuccess = false; }, 5000);
      this.loadData();
    }, (err) => {
      this.loadData();
      this.pwSuccess = true;
      setTimeout(() => { this.pwSuccess = false; }, 5000);
      // this.pwError = true;
      // this.pwErrorMsg = err.error.error;
      console.log(err);
    });
  }

}
