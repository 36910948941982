import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CacheUser } from './interfaces';

@Injectable({
    providedIn: 'root'
})
export class DebugService {

    private user: CacheUser;

    constructor(private http: HttpClient, private auth: AuthService) { 
        this.user = (this.auth.getCurrentUser() as CacheUser);
    }

    sendDebugMessage(msg: {[key:string]: any}) {
        msg.user = this.user;
        console.log(msg);
        this.http.post('/debug', msg);
    }

    sendErrorMessage(msg: {[key:string]: any}) {
        msg.user = this.user;
        console.error(msg);
        this.http.post('/debug/error', msg);
    }

}
