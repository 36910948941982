import { Component, OnInit, Input } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { LectureService } from '../shared/lecture.service';
import { Affirmation, Lecture, User } from '../shared/interfaces';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as moment from 'moment';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';  

@Component({
    selector: 'app-admin-calendar',
    templateUrl: './admin-calendar.component.html',
    styleUrls: ['./admin-calendar.component.scss']
})
export class AdminCalendarComponent implements OnInit {

    @Input() data: 'all' | 'future' | 'past';

    thisMoment = moment;

    allLectures: Lecture[] = [];

    lectures: Lecture[] = [];

    selectedLecture: Lecture;

    attendanceList: {user: User, affirmations: Affirmation[], ok?: boolean}[];

    showNewLectureModal = false;
    showSuccessMsg = false;
    showEditLectureModal = false;
    showEditSuccessMsg = false;
    showLecutreAffirmationsModal = false;

    hoveredDate: NgbDate | null = null;
    fromFilter: NgbDate | null;
    toFilter: NgbDate | null;

    updateForm = this.fb.group({
        title: ['', Validators.required],
        info: [''],
        date: ['', Validators.required],
        start: ['', Validators.required],
        end: ['', Validators.required],
        seats: ['', Validators.required]
    });

    newForm = this.fb.group({
        title: ['Grundunterricht', Validators.required],
        info: [''],
        date: [this.calendar.getToday(), Validators.required],
        start: [{ hour: 19, minute: 0, second: 0 }, Validators.required],
        end: [{ hour: 20, minute: 30, second: 0 }, Validators.required],
        seats: [10, Validators.required]
    });

    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private router: Router,
        private fb: FormBuilder, private lectureService: LectureService, private toasts: ToastrService) {
    }

    ngOnInit(): void {
        this.fromFilter = this.data !== 'past' ? this.calendar.getToday() : this.calendar.getPrev(this.calendar.getToday(), 'd', 30);
        this.toFilter = this.data !== 'past' ? this.calendar.getNext(this.calendar.getToday(), 'd', 30) : this.calendar.getToday();
        this.loadData();
    }

    loadData() {
        if (this.data === 'all') {
            this.lectureService.getAll().subscribe(data => {
                this.allLectures = [...data];
                this.filterLectures();
            }, err => {
                console.log(err);
            });
        } else if (this.data === 'future') {
            const today = moment();
            this.lectureService.getAll().subscribe(data => {
                this.allLectures = [...data.filter((l) => {
                    const d = moment(l.date, 'YYYY-MM-DD');
                    if (d.isSameOrAfter(today, 'D')) {
                        return true;
                    } else {
                        return false;
                    }
                })];
                this.filterLectures();
            }, err => {
                console.log(err);
            });
        } else {
            this.lectureService.getAll().subscribe(data => {
                const today = moment();
                this.allLectures = [...data.filter((l) => {
                    const d = moment(l.date, 'YYYY-MM-DD');
                    if (d.isSameOrBefore(today, 'D')) {
                        return true;
                    } else {
                        return false;
                    }
                })];
                console.log(this.allLectures);
                this.filterLectures();
            }, err => {
                console.log(err);
            });
        }
    }

    openNewLectureModal() {
        this.showNewLectureModal = true;
    }

    openUpdateLectureModal(lecture) {
        this.selectedLecture = { ...lecture };
        this.updateForm = this.fb.group({
            title: [this.selectedLecture.title, Validators.required],
            info: [this.selectedLecture.info],
            date: [this.formatter.parse(this.selectedLecture.date), Validators.required],
            start: [{
                // tslint:disable-next-line:radix
                hour: parseInt(this.selectedLecture.start.split(':')[0]),
                // tslint:disable-next-line:radix
                minute: parseInt(this.selectedLecture.start.split(':')[1]),
            }, [Validators.required]],
            end: [{
                // tslint:disable-next-line:radix
                hour: parseInt(this.selectedLecture.end.split(':')[0]),
                // tslint:disable-next-line:radix
                minute: parseInt(this.selectedLecture.end.split(':')[1]),
            }, [Validators.required]],
            seats: [this.selectedLecture.seats, Validators.required]
        });
        this.showEditLectureModal = true;
    }

    openAffirmationsModal(lecture: Lecture) {
        this.selectedLecture = lecture;
        this.selectedLecture.date = moment(this.selectedLecture.date).format('DD.MM.YYYY');
        this.showLecutreAffirmationsModal = true;
        this.lectureService.getAttendaceReport(lecture._id).subscribe(data => {
            this.attendanceList = [];
            for(let i = 0; i < data.length; i++) {
                let index = this.attendanceList.findIndex((v) => v.user._id === data[i].user._id);
                let affWoUser = {...data[i]};
                delete affWoUser.user;
                if(index !== -1) {
                    this.attendanceList[index].affirmations.push(affWoUser);
                } else {
                    this.attendanceList.push({user: data[i].user, affirmations: [affWoUser]});
                }
            }
            for(let i = 0; i < this.attendanceList.length; i++) {
                let ok = this.attendanceList[i].affirmations.every(a => a.passed);
                this.attendanceList[i].ok = ok;
            }
        });
    }

    printAttendanceList() {
        var data = document.getElementById('attendance-print-wrapper');  
        html2canvas(data).then(canvas => {  
          // Few necessary setting options  
          var imgWidth = 208;   
          var pageHeight = 295;    
          var imgHeight = canvas.height * imgWidth / canvas.width;  
          var heightLeft = imgHeight;  
      
          const contentDataURL = canvas.toDataURL('image/png')  
          let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
          var position = 0;  
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
          pdf.save(this.selectedLecture.title + '_' + moment(this.selectedLecture.date,'DD.MM.YYYY').format('DD_MM_YY') + '.pdf'); // Generated PDF   
        }); 
    }

    updateLecture() {
        const l = {
            id: this.selectedLecture._id,
            title: this.updateForm.value.title,
            info: this.updateForm.value.info,
            date: this.lectureService.convertDpToMoment(this.updateForm.value.date).format('YYYY-MM-DD'),
            start: this.lectureService.convertTpToString(this.updateForm.value.start),
            end: this.lectureService.convertTpToString(this.updateForm.value.end),
            seats: this.updateForm.value.seats,
        };
        this.lectureService.update(l).subscribe((data) => {
            if (data._id) {
                this.loadData();
                this.showEditLectureModal = false;
                this.showEditSuccessMsg = true;
                setTimeout(() => {
                    this.showEditSuccessMsg = false;
                }, 5000);
            }
        }, (err) => {
            console.log(err);
        });
    }

    removeFromLecture(lectureId, userId) {
        const c = confirm('Anmledung des Fahrschülers wirklich löschen?');
        if (c) {
            this.lectureService.removeRegistration(lectureId, userId).subscribe((data) => {
                if (data._id) {
                    this.loadData();
                    this.showSuccessMsg = true;
                    setTimeout(() => {
                        this.showSuccessMsg = false;
                    }, 5000);
                }
            }, (err) => {
                console.log(err);
            });
        }
        console.log(lectureId, userId);
        // this.lectureService.
    }

    saveNewLecture() {
        this.lectureService.create({
            title: this.newForm.value.title,
            info: this.newForm.value.info,
            date: this.lectureService.convertDpToMoment(this.newForm.value.date).format('YYYY-MM-DD'),
            start: this.lectureService.convertTpToString(this.newForm.value.start),
            end: this.lectureService.convertTpToString(this.newForm.value.end),
            seats: this.newForm.value.seats,
        }).subscribe((data) => {
            if (data._id) {
                this.loadData();
                this.showNewLectureModal = false;
                this.showSuccessMsg = true;
                setTimeout(() => {
                    this.showSuccessMsg = false;
                }, 5000);
            }
        }, (err) => {
            console.log(err);
        });
        // this.showNewLectureModal = false;
    }

    getColorClass(availableSeats: number): string {
        if (availableSeats > 5) {
            return '';
        } else if (availableSeats > 2) {
            return 'text-warning';
        } else if (availableSeats === 0) {
            return 'text-muted';
        }
    }

    buildEmailsString(registrations): string {
        let mails = '';
        for(let i = 0; i < registrations.length; i++) {
            if(i == 0) {
                mails += registrations[i].mail;
            } else {
                mails += ',' + registrations[i].mail;
            }
        }
        return mails;
    }

    filterLectures() {
        console.log(this.allLectures);
        if (this.fromFilter && this.toFilter) {
            const momentTo = moment(`${this.toFilter.year}-${this.toFilter.month}-${this.toFilter.day}`, 'YYYY-M-D');
            const momentFrom = moment(`${this.fromFilter.year}-${this.fromFilter.month}-${this.fromFilter.day}`, 'YYYY-M-D');
            let tempDate;
            this.lectures = this.allLectures.filter((lecture) => {
                tempDate = moment(lecture.date, 'YYYY-MM-DD');
                if (momentFrom.isSameOrBefore(tempDate) && momentTo.isSameOrAfter(tempDate)) {
                    return true;
                } else {
                    return false;
                }
            });
            let aDate;
            let bDate;
            this.lectures.sort((a, b) => {
                aDate = moment(a.date, 'YYYY-MM-DD');
                bDate = moment(b.date, 'YYYY-MM-DD');
                // tslint:disable-next-line:radix
                return parseInt(aDate.format('x')) - parseInt(bDate.format('x'));
            });
        }
        this.checkIfLectureIsReady();
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromFilter && !this.toFilter) {
            this.fromFilter = date;
        } else if (this.fromFilter && !this.toFilter && date && date.after(this.fromFilter)) {
            this.toFilter = date;
            this.filterLectures();
        } else {
            this.toFilter = null;
            this.fromFilter = date;
        }
    }

    checkIfLectureIsReady() {
        let now = moment();
        let start, end;
        this.allLectures.forEach(l => {
            start = moment(l.date + ':' + l.start, 'YYYY-MM-DD:HH:mm').add(15, 'minutes');
            end = moment(l.date + ':' + l.end, 'YYYY-MM-DD:HH:mm');
            if(now.isBetween(start, end)) {
                this.toasts.success('Hier klicken um ' + l.title + ' beizutreten', 'Online Unterricht Verfügbar!', {
                    disableTimeOut: true,
                    closeButton: true,
                    positionClass: 'toast-top-center'
                }).onTap
                .pipe(take(1))
                .subscribe(() => this.router.navigateByUrl('/lecture/' + l._id) );
            }
        });
    }

    isHovered(date: NgbDate) {
        return this.fromFilter && !this.toFilter && this.hoveredDate && date.after(this.fromFilter) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toFilter && date.after(this.fromFilter) && date.before(this.toFilter);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromFilter) || (this.toFilter && date.equals(this.toFilter)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

}
