import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { CacheUser, Message } from '../shared/interfaces';
import { MessagesService } from '../shared/messages.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  tabs = {
    overview: true,
    mine: false
  }

  messages: Message[] = [];

  userName = '';

  constructor(private authService: AuthService, private msgService: MessagesService) { }

  ngOnInit(): void {
    this.userName = (this.authService.getCurrentUser() as CacheUser).firstName;
    this.msgService.getAll().subscribe((data) => {
      this.messages = data;
    });
  }

  changeTab(name: string) {
    if (name === 'overview') {
      this.tabs.overview = true;
      this.tabs.mine = false;
    }
    if (name === 'mine') {
      this.tabs.overview = false;
      this.tabs.mine = true;
    }
  }

  closeAlert($event) {
    $event.target.closest('.alert').remove();
    // console.log($event.target.closest('.alert'));
  }

}
