import { Component, OnInit, Input } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CacheUser, Lecture, User } from '../shared/interfaces';
import { FormBuilder } from '@angular/forms';
import { LectureService } from '../shared/lecture.service';
import { AuthService } from '../shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    @Input() registrationsOnly: boolean;

    thisMoment = moment;
    registrations: Lecture[] = [];
    allLectures: Lecture[] = [];
    lectures: Lecture[] = [];

    selectedLecture: Lecture;

    showRegistrationModal = false;
    showSuccessMsg = false;
    showErrorMsg = false;
    errorMsg = '';

    hoveredDate: NgbDate | null = null;
    fromFilter: NgbDate | null;
    toFilter: NgbDate | null;

    user: CacheUser;

    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private toasts: ToastrService,
        private fb: FormBuilder, private lectureService: LectureService, private authService: AuthService,
        private router: Router) {
        this.fromFilter = calendar.getToday();
        this.toFilter = calendar.getNext(calendar.getToday(), 'd', 20);
    }

    ngOnInit(): void {
        this.user = (this.authService.getCurrentUser() as CacheUser);
        this.loadData();
    }

    loadData() {
        if (this.registrationsOnly) {
            this.lectureService.getRegistrations().subscribe(data => {
                console.log(data);
                this.registrations = [...data];
                this.allLectures = [...data];
                this.filterLectures();
            }, err => {
                console.log(err);
            });
        } else {
            const today = moment();
            this.lectureService.getAll().subscribe(data => {
                this.allLectures = [...data.filter((l) => {
                    const d = moment(l.date, 'YYYY-MM-DD');
                    if (d.isSameOrAfter(today, 'D')) {
                        return true;
                    } else {
                        return false;
                    }
                })];
                this.filterLectures();
            }, err => {
                console.log(err);
            });
        }
    }

    openRegistrationModal(lecture) {
        this.selectedLecture = lecture;
        this.showRegistrationModal = true;
    }

    registerForLecture() {
        this.showRegistrationModal = false;
        this.lectureService.register(this.selectedLecture._id).subscribe((res: any) => {
            if (res._id) {
                this.loadData();
                this.showSuccessMsg = true;
                setTimeout(() => {
                    this.showSuccessMsg = false;
                }, 5000);
            }
        }, (err) => {
            this.errorMsg = err.error.error;
            this.showErrorMsg = true;
            console.log(err);
        });
    }

    getColorClass(availableSeats: number, ready: boolean = false): string {
        if(ready) return 'text-success'
        if (availableSeats > 5) {
            return '';
        } else if (availableSeats > 2) {
            return 'text-warning';
        } else if (availableSeats === 0) {
            return 'text-muted';
        }
    }

    filterLectures() {
        if (this.fromFilter && this.toFilter) {
            const momentTo = moment(`${this.toFilter.year}-${this.toFilter.month}-${this.toFilter.day}`, 'YYYY-M-D');
            const momentFrom = moment(`${this.fromFilter.year}-${this.fromFilter.month}-${this.fromFilter.day}`, 'YYYY-M-D');
            let tempDate;
            this.lectures = this.allLectures.filter((lecture) => {
                tempDate = moment(lecture.date, 'YYYY-MM-DD');
                if (momentFrom.isSameOrBefore(tempDate) && momentTo.isSameOrAfter(tempDate)) {
                    return true;
                } else {
                    return false;
                }
            });
            this.lectures = this.lectures.map(l => {
                for(let i = 0; i < l.registrations.length; i++) {
                    if(l.registrations[i]._id === this.user.userId || this.registrationsOnly) {
                        l.registered = true;
                        let start = moment(l.date + ':' + l.start, 'YYYY-MM-DD:HH:mm').subtract(5, 'minutes');
                        let end = moment(l.date + ':' + l.end, 'YYYY-MM-DD:HH:mm');
                        l.courseReady = moment().isBetween(start, end);
                        if(l.courseReady) this.toasts.success('Hier klicken um ' + l.title + ' beizutreten', 'Online Unterricht Verfügbar!', {
                            disableTimeOut: true,
                            positionClass: 'toast-top-center'
                        }).onTap
                        .pipe(take(1))
                        .subscribe(() => this.router.navigateByUrl('/lecture/' + l._id) );
                        break;
                    }
                }
                return l;
            });
            let aDate;
            let bDate;
            this.lectures.sort((a, b) => {
                aDate = moment(a.date, 'YYYY-MM-DD');
                bDate = moment(b.date, 'YYYY-MM-DD');
                // tslint:disable-next-line:radix
                return parseInt(aDate.format('x')) - parseInt(bDate.format('x'));
            });
        }
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromFilter && !this.toFilter) {
            this.fromFilter = date;
        } else if (this.fromFilter && !this.toFilter && date && date.after(this.fromFilter)) {
            this.toFilter = date;
            this.filterLectures();
        } else {
            this.toFilter = null;
            this.fromFilter = date;
        }
    }

    isHovered(date: NgbDate) {
        return this.fromFilter && !this.toFilter && this.hoveredDate && date.after(this.fromFilter) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toFilter && date.after(this.fromFilter) && date.before(this.toFilter);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromFilter) || (this.toFilter && date.equals(this.toFilter)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

}
