<div class="d-flex flex-row" id="lecture-content">
    <app-lecture-chat [(show)]="showChatPanel"></app-lecture-chat>
    <div id="main-video-wrapper">
        <div id="own-video-element" ngDraggable [ngClass]="{'hidden-video': user?.admin && !streamScreen}">
            <fa-icon [icon]="['fas', 'window-maximize']" *ngIf="ownVideoMinimized"
                placement="left" ngbTooltip="Eigene Videovorschau öffnen"
                (click)="toggleOwnVideoPreview(false)"></fa-icon>
            <fa-icon [icon]="['fas', 'window-minimize']" *ngIf="!ownVideoMinimized"
                placement="left" ngbTooltip="Eigene Videovorschau minimieren"
                (click)="toggleOwnVideoPreview(true)"></fa-icon>
            <video [muted]="true" #ownVideoElement [ngClass]="{'hidden-video': ownVideoMinimized}"></video>
        </div>
        <div id="admin-camera-video-element" ngDraggable>
            <video [muted]="false" #adminCameraVideoElement></video>
        </div>
        <h1 id="waiting-indicator" *ngIf="!adminConnected">Warten auf Fahrlehrer...</h1>
        <video id="main-video-element" [muted]="user?.admin" #mainVideoElement
            [ngClass]="{'hidden': showVideoGrid || !adminConnected}"></video>
        <app-lecture-video-grid *ngIf="showVideoGrid"></app-lecture-video-grid>
        <div id="video-ui" class="d-flex flex-row align-items-center justify-content-between"
            *ngIf="!browserNotSupported">
            <div class="d-flex flex-row justify-content-start">
                <button type="button" class="btn btn-circle btn-md" [class.btn-secondary]="!showChatPanel"
                    placement="right" ngbTooltip="Chat einblenden/ausblenden"
                    [class.btn-info]="showChatPanel" (click)="showChatPanel = !showChatPanel">
                    <fa-icon [icon]="['fas', 'comment']" [size]="'2x'" *ngIf="!showChatPanel"></fa-icon>
                    <fa-icon [icon]="['fas', 'comment-slash']" [size]="'2x'" *ngIf="showChatPanel"></fa-icon>
                </button>
            </div>
            <div class="d-flex flex-row justify-content-center">
                <button type="button" class="btn btn-circle btn-md" [class.btn-success]="videoEnabled"
                    [class.btn-warning]="!videoEnabled" placement="top" ngbTooltip="Webcam aktivieren/deaktivieren"
                    [class.btn-secondary]="videoEnabled && streamScreen" (click)="toggleVideo()" 
                    [disabled]="cameraAvailable === false">
                    <fa-icon [icon]="['fas', 'video']" [size]="'2x'" *ngIf="videoEnabled"></fa-icon>
                    <fa-icon [icon]="['fas', 'video-slash']" [size]="'2x'" *ngIf="!videoEnabled"></fa-icon>
                </button>
                <button type="button" class="btn btn-circle btn-md" [class.btn-success]="microphoneEnabled"
                placement="top" ngbTooltip="Mikrofon aktivieren/deaktivieren" [disabled]="microphoneAvailable === false"
                    [class.btn-warning]="!microphoneEnabled" (click)="toggleMicrophone()">
                    <fa-icon [icon]="['fas', 'microphone']" [size]="'2x'" *ngIf="microphoneEnabled"></fa-icon>
                    <fa-icon [icon]="['fas', 'microphone-slash']" [size]="'2x'" *ngIf="!microphoneEnabled"></fa-icon>
                </button>
                <button type="button" class="btn btn-danger btn-circle btn-md"
                placement="top" ngbTooltip="Kurs verlassen" (click)="leaveLecture()">
                    <fa-icon [icon]="['fas', 'phone-slash']" [size]="'2x'"></fa-icon>
                </button>
                <button type="button" class="btn btn-circle btn-md" [class.btn-secondary]="!handRaised"
                placement="top" ngbTooltip="Handzeichen für Wortmeldung"
                    [class.btn-info]="handRaised" (click)="toggleHand()" *ngIf="!user?.admin">
                    <fa-icon [icon]="['far', 'hand-paper']" [size]="'2x'" *ngIf="!handRaised"></fa-icon>
                    <fa-icon [icon]="['fas', 'hand-paper']" [size]="'2x'" *ngIf="handRaised"></fa-icon>
                </button>
                <button type="button" class="btn btn-circle btn-md" [class.btn-secondary]="!streamScreen"
                placement="top" ngbTooltip="Bildschirmübertragung aktivieren"
                    [class.btn-success]="streamScreen" (click)="toggleScreenSharing()" *ngIf="user?.admin">
                    <fa-icon [icon]="['fas', 'desktop']" [size]="'2x'" *ngIf="!streamScreen"></fa-icon>
                    <fa-icon [icon]="['fas', 'camera']" [size]="'2x'" *ngIf="streamScreen"></fa-icon>
                </button>
            </div>
            <div class="d-flex flex-row justify-content-end">
                <button type="button" class="btn btn-info btn-md" (click)="muteAllParticipants()" *ngIf="user?.admin">
                    Fahrschüler Stumm Schalten
                </button>
                <button type="button" class="btn btn-circle btn-md" [class.btn-secondary]="!showVideoGrid"
                    placement="left" ngbTooltip="Videos der Fahrschüler in einem separaten Fenster anzeigen"
                    [class.btn-info]="showVideoGrid" (click)="openVideoGridExternalWindow()" *ngIf="user?.admin">
                    <fa-icon [icon]="['fas', 'th-large']" [size]="'2x'"></fa-icon>
                </button>
                <button type="button" class="btn btn-circle btn-md" [class.btn-secondary]="!showParticipantsPanel"
                    [class.btn-info]="showParticipantsPanel" (click)="showParticipantsPanel = !showParticipantsPanel"
                    *ngIf="user?.admin" placement="left" ngbTooltip="Liste der aktiven Teilnehmer anzeigen/ausblenden">
                    <fa-icon [icon]="['fas', 'users']" [size]="'2x'" *ngIf="!showParticipantsPanel"></fa-icon>
                    <fa-icon [icon]="['fas', 'users-slash']" [size]="'2x'" *ngIf="showParticipantsPanel"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <app-lecture-participant-list [(show)]="showParticipantsPanel" *ngIf="user?.admin"></app-lecture-participant-list>
</div>
<ng-template cdkPortal>
    <app-lecture-video-grid></app-lecture-video-grid>
</ng-template>
<div id="audio-wrapper">
    <app-lecture-audio [stream]="stream" *ngFor="let stream of mediaStreams"></app-lecture-audio>
</div>
<swal #wrongBrowserSwal title="Fehler" [focusCancel]="false" [allowEscapeKey]="false" [allowOutsideClick]="false"
    text="Dein von dir verwendeter Browser wird nicht unterstützt. Bitte verwende die aktuelle Version von Google Chrome bzw. Mozilla Firefox! {{browser === 'GSA' ? 'Du verwendest die Google Such App. Diese ist nicht der Chrome Webbrowser von Google!' : ''}}"
    icon="error" [showCancelButton]="false" (confirm)="goBack()">
</swal>
<swal #initialSwal title="Beitreten" icon="info" (confirm)="initialChoiceMade()" [allowEscapeKey]="false" [allowOutsideClick]="false">
    <form *swalPortal [formGroup]="initialDeviceSelectionForm">
        <p>
            Du willst einem Onlineunterricht beitreten. Dafür benötigt die Seite Zugriff auf deine Kamera und
            Mikrofon. Dein Browser wird dich fragen, ob du den Zugriff erlauben möchtest. Bitte bestätige diese Meldung!
        </p>
        <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" formControlName="mic" id="customSwitch1">
            <label class="custom-control-label" for="customSwitch1">Mikrofon</label>
        </div>
        <div class="custom-control custom-switch" *ngIf="cameraAvailable" >
            <input type="checkbox" class="custom-control-input" formControlName="camera" id="customSwitch2">
            <label class="custom-control-label" for="customSwitch2">Video</label>
        </div>
        <p>
            Keine Sorge, du kannst deine Kamera bzw. Mikrofon auch jederzeit deaktivieren bzw aktivieren!
        </p>
    </form>
</swal>
<swal #affirmationSwal title="Anwesenheit" icon="warning" [allowEscapeKey]="false" [allowOutsideClick]="false" (confirm)="confirmPresence()">
    <form *swalPortal [formGroup]="affirmationCodeForm">
        <p>Bitte gib folgenden Code ein: 
            <span style="font-family: 'Roboto Mono', monospace;">{{currAffirmation?.code}}</span>
        </p>
        <p style="text-align: center;">
            <fa-icon [icon]="['fas', 'info-circle']" ngbPopover="Die Auflagen des Landratamtes fordern eine Überprüfung der Anwesenheit. Um dieser Auflage gerecht zu werden, musst du während der Unterrichts 2x einen Code eingeben. Damit bestätigst du, dass du aufmerksam am Unterreicht teilgenommen hast."
                [closeDelay]="500" triggers="mouseenter:mouseleave"></fa-icon>
        </p>
        <input type="text" style="font-family: 'Roboto Mono', monospace;" formControlName="code">
    </form>
    <ng-container *swalPortal="swalTargets.confirmButton">
        Anwesenheit Bestätigen
    </ng-container>
</swal>
<swal #notStartedSwal title="Unterricht geschlossen" icon="error" [allowEscapeKey]="false" [allowOutsideClick]="false"
    text="Du versuchst an einem Online Unterricht außerhalb der Zeiten teilzunehmen." [showCancelButton]="false" (confirm)="goBack()">
</swal>
<swal #permissionsErrorSwal title="Fehlende Berechtigungen" icon="error" [allowEscapeKey]="false" [allowOutsideClick]="false"
    text="Um am Onlineunterricht teilnehmen zu können, benötigt die Website Zugriff auf dein Mikrofon (und Kamera, falls eine angeschlossen ist). Bitte informiere dich selbst, wie du in deinem Browser die Berechtigung setzten kannst. Dann kannst du es erneut versuchen." [showCancelButton]="false" (confirm)="goBack()">
</swal>
<ngx-spinner type="pacman" [zIndex]="1000">
    <p style="font-size: 20px; color: white">Verbindung aufbauen...</p>
</ngx-spinner>