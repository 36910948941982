import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { ProfilComponent } from './profil/profil.component';
import { AuthGuardService } from './shared/auth-guard.service';
import { UserListComponent } from './user-list/user-list.component';
import { AdminsMessagesComponent } from './admins-messages/admins-messages.component';
import { LectureComponent } from './lecture/lecture.component';


// { path: '', component: HomeComponent, pathMatch: 'full', canActivate : [AuthGuardService] },
const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'lecture/:id', component: LectureComponent },
  { path: 'lecture', component: LectureComponent },
  { path: 'login/register', component: RegistrationComponent },
  { path: 'admin', component: AdminComponent, pathMatch: 'full'},
  { path: 'admin/messages', component: AdminsMessagesComponent},
  { path: 'admin/user', component: UserListComponent},
  { path: 'profil', component: ProfilComponent}
];

@NgModule({
  providers: [AuthGuardService],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
