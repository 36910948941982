import { EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LectureUser, User } from '../shared/interfaces';
import { LectureRtcService } from '../shared/lecture-rtc.service';
import { LectureWsService } from '../shared/lecture-ws.service';
import * as rectScaler from 'rect-scaler';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { LectureMeetingService } from '../shared/lecture-meeting.service';

@Component({
    selector: 'app-lecture-video-grid',
    templateUrl: './lecture-video-grid.component.html',
    styleUrls: ['./lecture-video-grid.component.scss']
})
export class LectureVideoGridComponent implements OnInit, OnDestroy {

    @Input() show: boolean;
    @Output() showChange = new EventEmitter<boolean>();
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.calculateLayout();
    }


    public participants: LectureUser[] = [];

    public rows: number = 0;
    public cols: number = 0;
    public width: number = 0;
    public height: number = 0;
    public gridWidth: number = 0;

    private allpartSubscription: Subscription;
    private partSubscription: Subscription;
    private newVideoSubscription: Subscription;

    constructor(private meetingService: LectureMeetingService) { }

    ngOnInit(): void {
        this.allpartSubscription = this.meetingService.allParticipantsStatesObservable$.subscribe(data => {
            // this.participants = [];
            this.participants = data;
            this.calculateLayout();
        });
        // this.newVideoSubscription = this.meetingService.newVideoObservable$.subscribe(data => {
        //     for (let j = 0; j < this.participants.length; j++) {
        //         if (data.user.id === this.participants[j].id) {
        //             this.participants[j].stream = data.stream;
        //             this.participants[j].state.videoEnabled = data.stream.getVideoTracks()[0].muted;
        //             this.participants[j].state.micEnabled = data.stream.getAudioTracks()[0].muted;
        //         }
        //     }
        //     this.calculateLayout();
        // });
    }

    calculateLayout2(containerWidth: number,containerHeight: number,videoCount: number,
        aspectRatio: number): { width: number; height: number; cols: number } {
        let bestLayout = {
            area: 0,
            cols: 0,
            rows: 0,
            width: 0,
            height: 0
        };

        // brute-force search layout where video occupy the largest area of the container
        for (let cols = 1; cols <= videoCount; cols++) {
            const rows = Math.ceil(videoCount / cols);
            const hScale = containerWidth / (cols * aspectRatio);
            const vScale = containerHeight / rows;
            let width;
            let height;
            if (hScale <= vScale) {
                width = Math.floor(containerWidth / cols);
                height = Math.floor(width / aspectRatio);
            } else {
                height = Math.floor(containerHeight / rows);
                width = Math.floor(height * aspectRatio);
            }
            const area = width * height;
            if (area > bestLayout.area) {
                bestLayout = {
                    area,
                    width,
                    height,
                    rows,
                    cols
                };
            }
        }
        return bestLayout;
    }

    calculateLayout() {
        const containerWidth = document.body.getBoundingClientRect().width;
        const containerHeight = document.body.getBoundingClientRect().height;
        const numRects = this.participants.length;
        const layoutResult = this.calculateLayout2(containerWidth, containerHeight, numRects, (16 / 9));
        this.cols = layoutResult.cols;
        this.width = layoutResult.width;
        this.height = layoutResult.height;
        this.gridWidth = this.width * this.cols;
        console.log({ layoutResult });
    }

    ngOnDestroy(): void {
        if (this.allpartSubscription) this.allpartSubscription.unsubscribe();
        if (this.partSubscription) this.partSubscription.unsubscribe();
        if (this.newVideoSubscription) this.newVideoSubscription.unsubscribe();
    }

}
