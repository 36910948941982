import { Component, OnInit, OnChanges, AfterContentChecked, OnDestroy } from '@angular/core';
import { UserService } from './shared/user.service';
import { AuthService } from './shared/auth.service';
import { CacheUser } from './shared/interfaces';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnChanges, AfterContentChecked, OnDestroy {

    currentUser: CacheUser;
    showMobileMenu = false;
    lectureDesign = false;
    routeSubscription: Subscription;

    constructor(private authService: AuthService, private router: Router) { }

    ngOnChanges() {
        this.currentUser = (this.authService.getCurrentUser() as CacheUser);
    }

    ngOnInit() {
        this.routeSubscription = this.router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                if (e.url.indexOf('/lecture') === 0) {
                    this.lectureDesign = true;
                } else {
                    this.lectureDesign = false;
                }
            }
        });
        this.currentUser = (this.authService.getCurrentUser() as CacheUser);
    }

    ngAfterContentChecked() {
        // console.log('app comp content checked');
        this.currentUser = (this.authService.getCurrentUser() as CacheUser);
    }

    logOut() {
        this.authService.logOut();
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
    }

}
