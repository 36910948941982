import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpClient, private authService: AuthService) { }

	login(mail: string, pw: string) {
		return this.http.post('/login', {
			mail,
			password: pw
		});
	}

	getDetails() {
		return this.http.get('/api/user/details');
	}

	getBasicInfo(id) {
		return this.http.get('/api/user/basic/' + id);
	}

	getAll() {
		return this.http.get('/api/user');
	}

	getAllLocked() {
		return this.http.get('/api/user/locked');
	}

	lockUser(id) {
		return this.http.get('/api/user/lock/' + id);
	}

	unlockUser(id) {
		return this.http.get('/api/user/unlock/' + id);
	}

	update(user) {
		const uId = JSON.parse(localStorage.getItem('userInfo')).userId;
		return this.http.post('/api/user/update', { id: uId, firstName: user.firstName, lastName: user.lastName, mail: user.mail });
	}

	updatePassword(pw) {
		const uId = JSON.parse(localStorage.getItem('userInfo')).userId;
		return this.http.post('/api/password/update', { id: uId, newPassword: pw.newPassword, currentPassword: pw.currentPassword });
	}
}
