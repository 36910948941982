
<button class="btn btn-block btn-success" (click)="openNewMsgModal()">Neuen nachricht erstellen</button>
<p></p>
<p></p>
<div class="accordion" id="accordionExample">
  <div class="card" *ngIf="messages.length === 0">
    <div class="card-header d-flex justify-content-between" >
      <h6 class="m-0 p-0 flex-fill">Keine Nachrichten vorhanden!</h6>
    </div>
  </div>
  <div class="card mb-1" *ngFor="let message of messages">
    <div class="card-header d-flex justify-content-between" (click)="message.expanded = message.expanded ? false : true">
      <h6 class="m-0 p-0 flex-fill" >{{message.title}} <span class="badge badge-light float-right">{{message.created_at | date}}</span></h6>
    </div>

    <div id="collapseOne" class="collapse show" *ngIf="message.expanded">
      <div class="card-body" [innerHTML]="message.content"></div>
      <button type="button" class="btn btn-outline-warning btn-sm btn-block" (click)="deleteMessage(message)"><fa-icon [icon]="['far', 'trash-alt']"></fa-icon> Nachricht löschen</button>
    </div>
  </div>
</div>

<div class="modal fade" [ngClass]="{'show': showNewMsgModal}" role="dialog"
  [ngStyle]="{'display': showNewMsgModal ? 'block' : 'none', 'background-color': 'rgba(0,0,0,0.5)'}"
  (click)="showNewMsgModal = false;">
  <div class="modal-dialog modal-lg" role="document" (click)="$event.preventDefault();$event.stopPropagation();">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Neue Nachricht erstellen</h5>
        <button type="button" class="close" (click)="showNewMsgModal = false;" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="newForm">
          <div class="form-group row">
            <label for="newTitleInput" class="col-md-3 col-form-label">Überschrift</label>
            <div class="col-md-9">
              <input type="text" class="form-control" id="newTitleInput" formControlName="title">
            </div>
          </div>
          <div class="form-group row">
            <label for="newContentInput" class="col-md-3 col-form-label">Inhalt</label>
            <div class="col-md-9">
              <quill-editor [styles]="{height: '200px'}" (onEditorChanged)="changedEditor($event)" formControlName="content"></quill-editor>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary"
          (click)="showNewMsgModal = false;">Abbrechen</button>
        <button type="button" class="btn btn-success" (click)="saveNewMessage()">Nachricht erstellen</button>
      </div>
    </div>
  </div>
</div>
