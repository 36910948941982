<div class="card card-bordered" *ngIf="show">
    <div class="card-header">
        <h4 class="card-title"><strong>Chat</strong></h4>
        <button class="btn btn-xs btn-secondary" (click)="toggleVisibility()">Minimieren</button>
    </div>
    <div #chatScrollContainer class="ps-container ps-theme-default ps-active-y" 
        (scroll)="onScroll()" id="chat-content" style="overflow-y: scroll !important; height: 100%;">
        <ng-container *ngFor="let msg of messages">
            <div class="media media-chat" *ngIf="msg.from !== ownUserId">
                <ngx-avatar name="{{msg.user.firstName}} {{msg.user.lastName}}"></ngx-avatar>
                <div class="media-body">
                    <p>{{msg.content}}</p>
                    <small>{{msg.created_at | date:'HH:mm' }} - {{msg.user.firstName}}</small>
                </div>
            </div>
            <div class="media media-chat media-chat-reverse" *ngIf="msg.from === ownUserId">
                <div class="media-body">
                    <p>{{msg.content}}</p>
                    <small>{{msg.created_at | date:'HH:mm' }}</small>
                </div>
            </div>
        </ng-container>
        <div class="media media-chat" *ngIf="messages.length < 1">
            <div class="media-body">
                <p>Noch keine Nachrichten vorhanden...</p>
            </div>
        </div>
        <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px;">
            <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
        </div>
        <div class="ps-scrollbar-y-rail" style="top: 0px; height: 0px; right: 2px;">
            <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 2px;"></div>
        </div>
    </div>
    <div class="publisher bt-1 border-light">
        <img class="avatar avatar-xs" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="...">
        <input class="publisher-input" type="text" placeholder="Nachricht schreiben..." (keydown.enter)="submitMsg()" [(ngModel)]="msgInput">
        <!-- <a class="publisher-btn" href="#" data-abc="true"><i class="fa fa-smile"></i></a> -->
        <button class="publisher-btn text-info" (click)="submitMsg()"><fa-icon [icon]="['fas', 'paper-plane']"></fa-icon></button> </div>
</div>
