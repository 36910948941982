import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { Subscription } from 'rxjs';
import { Message } from '../shared/interfaces';
import { MessagesService } from '../shared/messages.service';

@Component({
  selector: 'app-admins-messages',
  templateUrl: './admins-messages.component.html',
  styleUrls: ['./admins-messages.component.scss']
})
export class AdminsMessagesComponent implements OnInit, OnDestroy {

  messages: Message[] = [];
  showNewMsgModal: boolean = false;
  newForm: FormGroup;
  msgSubscription: Subscription;
  addMessageSubscription: Subscription;
  deleteMessageSubscription: Subscription;

  constructor(private msgService: MessagesService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.newForm = this.fb.group({
      title: ['', Validators.required],
      content: ['', Validators.required]
    });
    this.loadMessages();
  }

  ngOnDestroy() {
    if(this.msgSubscription) {
      this.msgSubscription.unsubscribe();
    }
    if(this.deleteMessageSubscription) {
      this.deleteMessageSubscription.unsubscribe();
    }
  };

  loadMessages() {
    if(this.msgSubscription) {
      this.msgSubscription.unsubscribe();
    }
    this.msgSubscription = this.msgService.getAll().subscribe(data => {
      console.log(data);
      this.messages = data;
    });
  }

  saveNewMessage() {
    console.log(this.newForm);
    if(this.addMessageSubscription) {
      this.addMessageSubscription.unsubscribe();
    }
    this.addMessageSubscription = this.msgService.addMessage(this.newForm.value).subscribe(newMsgResponse => {
      console.log(newMsgResponse);
      if(newMsgResponse) {
        this.loadMessages();
        this.showNewMsgModal = false;
      }
    })
  }

  deleteMessage(message) {
    console.log(message);
    if(this.deleteMessageSubscription) {
      this.deleteMessageSubscription.unsubscribe();
    }
    this.deleteMessageSubscription = this.msgService.delete(message).subscribe(data => {
      console.log({deleteData: data});
      this.loadMessages();
    })
  }

  openNewMsgModal() {
    this.showNewMsgModal = true;
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    // tslint:disable-next-line:no-console
    console.log('editor-change', event)
  }

}
