import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Affirmation, CacheUser, ChatMessage, LectureUser, LectureUserEvent, User, UserState } from './interfaces';
import { LectureWsService } from './lecture-ws.service';
import { UserService } from './user.service';
import { DebugService } from './debug.service';



@Injectable({
    providedIn: 'root'
})
export class LectureMeetingService {

    private socket: Socket;
    private JitsiMeet;
    private jitsiConnection;
    private jitsiConference;
    private jitsiScreenConference;
    private jitsiScreenConnection;

    private user: CacheUser;
    private state: UserState;
    private lectureId: string;
    private cameraAvailable: boolean;
    private ownCameraStreamInit: boolean = false;
    private ownScreenStreamInit: boolean = false;
    private ownCameraStream: MediaStream;
    private ownScreenStream: MediaStream;

    private chatMessages: ChatMessage[] = [];
    private participants: LectureUser[] = [];
    private adminScreen: LectureUser;
    private currentUserStates: { user: User, state: UserState }[] = [];

    private errorSubject$ = new Subject();
    errorObservable$ = this.errorSubject$.asObservable();

    private socketConnectedSubject$ = new Subject();
    socketConnectedObservable$ = this.socketConnectedSubject$.asObservable();

    private ownVideoSubject$ = new Subject<MediaStream>();
    ownVideoObservable$ = this.ownVideoSubject$.asObservable();

    private adminVideoSubject$ = new Subject<MediaStream>();
    adminVideoObservable$ = this.adminVideoSubject$.asObservable();

    private adminScreenSubject$ = new Subject<MediaStream>();
    adminScreenObservable$ = this.adminScreenSubject$.asObservable();

    private participantsSubject$ = new BehaviorSubject<LectureUserEvent>({ participants: this.participants });
    participantsObservable$ = this.participantsSubject$.asObservable();

    private messagesSubject$ = new BehaviorSubject<ChatMessage[]>([]);
    messagesObservable$ = this.messagesSubject$.asObservable();

    private adminMsgSubject$ = new Subject<string>();
    adminMsgObservable$ = this.adminMsgSubject$.asObservable();

    private allMessagesSubject$ = new BehaviorSubject<ChatMessage[]>(this.chatMessages);
    allMessagesObservable$ = this.allMessagesSubject$.asObservable();

    private allParticipantsStatesSubject$ = new BehaviorSubject<LectureUser[]>(this.participants);
    allParticipantsStatesObservable$ = this.allParticipantsStatesSubject$.asObservable();

    private allParticipantsSubject$ = new BehaviorSubject<LectureUser[]>(this.participants);
    allParticipantsObservable$ = this.allParticipantsSubject$.asObservable();

    private alreadyInRoomParticipantsSubject$ = new Subject<LectureUser>();
    alreadyInRoomParticipantsObservable$ = this.alreadyInRoomParticipantsSubject$.asObservable();

    private newParticipantsSubject$ = new Subject<LectureUser>();
    newParticipantsObservable$ = this.newParticipantsSubject$.asObservable();

    private leftParticipantsSubject$ = new Subject<LectureUser>();
    leftParticipantsObservable$ = this.leftParticipantsSubject$.asObservable();

    private handRaisedSubject$ = new Subject<{ user: LectureUser, raised: boolean }>();
    handRaisedObservable$ = this.handRaisedSubject$.asObservable();

    private handChangedSubject$ = new Subject<{ user: LectureUser, raised: boolean }>();
    handChangedObservable$ = this.handChangedSubject$.asObservable();

    private videoChangedSubject$ = new Subject<{ user: LectureUser, enabled: boolean }>();
    videoChangedObservable$ = this.videoChangedSubject$.asObservable();

    private micChangedSubject$ = new Subject<{ user: LectureUser, enabled: boolean }>();
    micChangedObservable$ = this.micChangedSubject$.asObservable();

    private adminScreenSharingSubject$ = new Subject<{ enabled: boolean, user: LectureUser }>();
    adminScreenSharingObservable$ = this.adminScreenSharingSubject$.asObservable();

    private newVideoSubject$ = new Subject<{ user: LectureUser, stream: MediaStream }>();
    public newVideoObservable$ = this.newVideoSubject$.asObservable();

    private endVideoSubject$ = new Subject<{ user: LectureUser, stream: MediaStream }>();
    public endVideoObservable$ = this.endVideoSubject$.asObservable();

    private endAdminVideoSubject$ = new Subject<{ user: LectureUser, stream: MediaStream }>();
    public endAdminVideoObservable$ = this.endAdminVideoSubject$.asObservable();

    private screenSharingVideoSubject = new Subject<MediaStream>();
    public screenSharingVideoObservable$ = this.screenSharingVideoSubject.asObservable();

    private screenSharingStartedSubject = new Subject<boolean>();
    public screenSharingStartedObservable$ = this.screenSharingStartedSubject.asObservable();

    private screenSharingEndedSubject = new Subject<boolean>();
    public screenSharingEndedObservable$ = this.screenSharingEndedSubject.asObservable();

    private ownScreenSharingStartedSubject = new Subject<MediaStream>();
    public ownScreenSharingStartedObservable$ = this.ownScreenSharingStartedSubject.asObservable();

    private ownScreenSharingEndedSubject = new Subject<boolean>();
    public ownScreenSharingEndedObservable$ = this.ownScreenSharingEndedSubject.asObservable();

    private gotMutedSubject = new Subject<boolean>();
    public gotMutedObservable$ = this.gotMutedSubject.asObservable();

    private affirmationSubject$ = new Subject<Affirmation>();
    affirmationObservable$ = this.affirmationSubject$.asObservable();

    private meetingConnectionSubject$ = new Subject<{ event: string }>();
    meetingConnectionObservable$ = this.meetingConnectionSubject$.asObservable();

    constructor(private authService: AuthService, private debug: DebugService) {
        this.user = (this.authService.getCurrentUser() as CacheUser);
        this.socket = io(environment.SOCKET_ENDPOINT);
        this.debug.sendDebugMessage({ event: 'ws connected', from: 'meeting service constructor' });
        this.setupWsListener();
        this.JitsiMeet = (window as any).JitsiMeetJS;
    }

    private async setupScreenConnection(lectureId) {
        return new Promise((res, rej) => {
            this.jitsiScreenConnection = new this.JitsiMeet.JitsiConnection(null, null, {
                hosts: {
                    domain: 'meetings.die-fahrschule-unterricht.de',
                    muc: 'conference.meetings.die-fahrschule-unterricht.de'
                },
                bosh: '//meetings.die-fahrschule-unterricht.de/http-bind',
                clientNode: 'fs-unterricht'
            });
            this.debug.sendDebugMessage({ event: 'jitsi screen connection setup', from: 'meeting service constructor' });

            this.jitsiScreenConnection.addEventListener(this.JitsiMeet.events.connection.CONNECTION_ESTABLISHED, () => {
                this.debug.sendDebugMessage({ event: 'jitsi screen connection established', from: 'meeting service constructor' });


                this.jitsiScreenConference = this.jitsiScreenConnection.initJitsiConference(lectureId, {
                    openBridgeChannel: true
                });
                this.jitsiScreenConference.on(this.JitsiMeet.events.conference.CONFERENCE_JOINED, () => {
                    this.debug.sendDebugMessage({ event: 'screen conference joined', from: 'meeting service join room', lectureId });
                    console.log('SCREEN_CONFERENCE_JOINED');
                    res(true);
                });
                this.jitsiScreenConference.setDisplayName(this.user.firstName);
                this.jitsiScreenConference.setLocalParticipantProperty('firstName', this.user.firstName);
                this.jitsiScreenConference.setLocalParticipantProperty('lastName', this.user.lastName);
                this.jitsiScreenConference.setLocalParticipantProperty('screensharing', true);
                this.jitsiScreenConference.join();

                console.log('SCREEN_CONNECTION_ESTABLISHED');
            });
            this.jitsiScreenConnection.addEventListener(this.JitsiMeet.events.connection.CONNECTION_FAILED, (err) => {
                this.debug.sendDebugMessage({ event: 'jitsi screen connection failed', from: 'meeting service constructor', err });
                console.log('SCREEN_CONNECTION_FAILED');
            });
            this.jitsiScreenConnection.addEventListener(this.JitsiMeet.events.connection.CONNECTION_DISCONNECTED, (reason) => {
                this.debug.sendDebugMessage({ event: 'jitsi screen connection disconnected', from: 'meeting service constructor', reason });
                console.log('SCREEN_CONNECTION_DISCONNECTED');
            });
            this.jitsiScreenConnection.addEventListener(this.JitsiMeet.events.connection.WRONG_STATE, (reason) => {
                this.debug.sendDebugMessage({ event: 'jitsi screen connection wrong state', from: 'meeting service constructor', reason });
                console.log('SCREEN_CONNECTION_WRONG_STATE');
            });

            this.jitsiScreenConnection.connect();
        });
    }

    public initJitsi() {
        this.JitsiMeet.init({disableSimulcast: true});
        this.jitsiConnection = new this.JitsiMeet.JitsiConnection(null, null, {
            hosts: {
                domain: 'meetings.die-fahrschule-unterricht.de',
                muc: 'conference.meetings.die-fahrschule-unterricht.de'
            },
            bosh: '//meetings.die-fahrschule-unterricht.de/http-bind',
            clientNode: 'fs-unterricht'
        });
        this.debug.sendDebugMessage({ event: 'jitsi connection setup', from: 'meeting service constructor' });

        this.jitsiConnection.addEventListener(this.JitsiMeet.events.connection.CONNECTION_ESTABLISHED, () => {
            this.debug.sendDebugMessage({ event: 'jitsi connection established', from: 'meeting service constructor' });
            this.meetingConnectionSubject$.next({ event: 'connection-established' });
            console.log('CONNECTION_ESTABLISHED');
        });
        this.jitsiConnection.addEventListener(this.JitsiMeet.events.connection.CONNECTION_FAILED, (err) => {
            this.debug.sendErrorMessage({ event: 'jitsi connection failed', from: 'meeting service constructor', err });
            console.warn(err);
            console.log('CONNECTION_FAILED');
        });
        this.jitsiConnection.addEventListener(this.JitsiMeet.events.connection.CONNECTION_DISCONNECTED, (reason) => {
            this.debug.sendDebugMessage({ event: 'jitsi connection disconnected', from: 'meeting service constructor', reason });
            console.log('CONNECTION_DISCONNECTED');
        });

        this.jitsiConnection.connect();
    }

    public joinRoom(lectureId, videoEnabled, micEnabled, cameraAvailable) {
        this.cameraAvailable = cameraAvailable;
        this.lectureId = lectureId;
        this.state = {
            handRaised: false,
            videoEnabled,
            micEnabled,
            screenSharing: false
        };


        this.debug.sendDebugMessage({ event: 'join-room', from: 'meeting service join room', lectureId, videoEnabled, micEnabled });

        this.socket.emit('join-lecture', this.lectureId, this.user.userId, this.state);

        this.socket.once('lecture-not-started', () => {
            this.errorSubject$.next('lecture-not-started');
        });

        this.socket.once('access-granted', () => {
            this.jitsiConference = this.jitsiConnection.initJitsiConference(this.lectureId, {
                openBridgeChannel: true
            });

            this.jitsiConference.on(this.JitsiMeet.events.conference.TRACK_ADDED, (track) => {
                this.debug.sendDebugMessage({ event: 'track-added-to-conference', from: 'meeting service join room', lectureId, track, trackParticipantId: track.getParticipantId() });
                if (this.jitsiConference.myUserId() === track.getParticipantId()) return;
                if (this.adminScreen?.jitsiId === track.getParticipantId()) {
                    if (this.adminScreen.stream) {
                        let audioTracks: MediaStreamTrack[] = this.adminScreen.stream.getAudioTracks();
                        let videoTracks: MediaStreamTrack[] = this.adminScreen.stream.getVideoTracks();
                        if (track.track.kind === "audio") {
                            if (audioTracks.length === 1) {
                                this.adminScreen.stream.removeTrack(audioTracks[0]);
                            }
                            this.adminScreen.stream.addTrack(track.track);
                        }
                        if (track.track.kind === "video") {
                            if (videoTracks.length === 1) {
                                this.adminScreen.stream.removeTrack(videoTracks[0]);
                            }
                            this.adminScreen.stream.addTrack(track.track);
                        }
                    } else {
                        this.adminScreen.stream = new MediaStream([track.track]);
                    }
                    this.screenSharingVideoSubject.next(this.adminScreen.stream);
                    this.jitsiConference.selectParticipant(this.adminScreen.jitsiId);
                } else {
                    let i = this.getIndexOfJitsiUser(track.getParticipantId());
                    if (i !== -1) {
                        if (this.participants[i].stream) {
                            let audioTracks: MediaStreamTrack[] = this.participants[i].stream.getAudioTracks();
                            let videoTracks: MediaStreamTrack[] = this.participants[i].stream.getVideoTracks();

                            if (track.track.kind === "audio") {
                                if (audioTracks.length === 1) {
                                    this.participants[i].stream.removeTrack(audioTracks[0]);
                                }
                                this.participants[i].stream.addTrack(track.track);
                            }
                            if (track.track.kind === "video") {
                                if (videoTracks.length === 1) {
                                    this.participants[i].stream.removeTrack(videoTracks[0]);
                                }
                                this.participants[i].stream.addTrack(track.track);
                            }
                        } else {
                            this.participants[i].stream = new MediaStream([track.track]);
                        }

                        if (this.participants[i].admin === true) {
                            this.adminVideoSubject$.next(this.participants[i].stream);
                        }
                        this.participantsSubject$.next({ participants: this.participants, eventType: 'new' });
                    }
                }
            });

            this.jitsiConference.on(this.JitsiMeet.events.conference.TRACK_REMOVED, (track) => {
                if (this.jitsiConference.myUserId() === track.getParticipantId()) return;
                if (this.adminScreen?.jitsiId === track.getParticipantId()) {
                    this.debug.sendDebugMessage({ event: 'screen-track-removed-from-conference', from: 'meeting service join room', lectureId, track });
                    if (this.adminScreen.stream) {
                        let audioTracks: MediaStreamTrack[] = this.adminScreen.stream.getAudioTracks();
                        let videoTracks: MediaStreamTrack[] = this.adminScreen.stream.getVideoTracks();
                        if (track.track.kind === "audio") {
                            if (audioTracks.length === 1) {
                                this.adminScreen.stream.removeTrack(audioTracks[0]);
                            }
                        }
                        if (track.track.kind === "video") {
                            if (videoTracks.length === 1) {
                                this.adminScreen.stream.removeTrack(videoTracks[0]);
                            }
                        }
                    }
                    if (this.adminScreen.stream.getTracks().length === 0) {
                        this.debug.sendDebugMessage({ event: 'all-screen-track-removed-from-conference', from: 'meeting service join room', lectureId, track });
                        this.adminScreen.state.screenSharing = false;
                        this.adminScreen.stream = undefined;
                        this.adminScreenSharingSubject$.next({ enabled: false, user: this.adminScreen });
                    } else {
                        this.screenSharingVideoSubject.next(this.adminScreen.stream);
                    }
                } else {
                    this.debug.sendDebugMessage({ event: 'track-removed-from-conference', from: 'meeting service join room', lectureId, track });
                }
                // else {
                //     let i = this.getIndexOfJitsiUser(track.getParticipantId());
                //     if(i !== -1) {
                //         if(this.participants[i].stream) {
                //             let audioTracks: MediaStreamTrack[] = this.participants[i].stream.getAudioTracks();
                //             let videoTracks: MediaStreamTrack[] = this.participants[i].stream.getVideoTracks();

                //             if(track.track.kind === "audio") {
                //                 if(audioTracks.length === 1) {
                //                     this.participants[i].stream.removeTrack(audioTracks[0]);
                //                 }
                //                 this.participants[i].stream.addTrack(track.track);
                //             }
                //             if(track.track.kind === "video") {
                //                 if(videoTracks.length === 1) {
                //                     this.participants[i].stream.removeTrack(videoTracks[0]);
                //                 } 
                //                 this.participants[i].stream.addTrack(track.track);
                //             }
                //         } else {
                //             this.participants[i].stream = new MediaStream([track.track]);
                //         }

                //         if(this.participants[i].admin === true) {
                //             this.adminVideoSubject$.next(this.participants[i].stream);
                //         }
                //         this.participantsSubject$.next({participants: this.participants, eventType: 'change'});
                //     }
                // }
            });

            this.jitsiConference.on(this.JitsiMeet.events.conference.MESSAGE_RECEIVED, (id: string, text: string, ts: number) => {
                this.adminMsgSubject$.next(text);
            });

            this.jitsiConference.on(this.JitsiMeet.events.conference.USER_JOINED, (id, user) => {
                let i = this.getIndexOfJitsiUser(id);
                if (i === -1) {
                    this.participants.push({
                        id: user.getProperty('userId'),
                        jitsiId: id,
                        firstName: user.getProperty('firstName'),
                        lastName: user.getProperty('lastName'),
                        mail: user.getProperty('mail'),
                        admin: user.getProperty('admin') ? user.getProperty('admin') : false,
                        state: {
                            micEnabled: user.getProperty('micEnabled') ? true : false,
                            videoEnabled: user.getProperty('videoEnabled') ? true : false,
                            handRaised: user.getProperty('handRaised') ? true : false,
                            screenSharing: user.getProperty('screenSharingEnabled') ? true : false
                        }
                    });
                } else {
                    this.participants[i] = {
                        id: user.getProperty('userId'),
                        jitsiId: id,
                        firstName: user.getProperty('firstName'),
                        lastName: user.getProperty('lastName'),
                        mail: user.getProperty('mail'),
                        admin: user.getProperty('admin') ? user.getProperty('admin') : false,
                        state: {
                            micEnabled: user.getProperty('micEnabled') ? user.getProperty('micEnabled') ? true : false : this.participants[i].state.micEnabled,
                            videoEnabled: user.getProperty('videoEnabled') ? user.getProperty('videoEnabled') ? true : false : this.participants[i].state.videoEnabled,
                            handRaised: user.getProperty('handRaised') ? user.getProperty('handRaised') ? true : false : this.participants[i].state.handRaised,
                            screenSharing: user.getProperty('screenSharingEnabled') ? user.getProperty('screenSharingEnabled') ? true : false : this.participants[i].state.screenSharing
                        }
                    };
                }
            });

            this.jitsiConference.on(this.JitsiMeet.events.conference.USER_LEFT, (id, user) => {
                console.log({ user, id, screenStream: this.adminScreen.jitsiId === id });
                if (this.adminScreen.jitsiId === id) {
                    this.adminScreen = undefined;
                    
                    
                } else {
                    let i = this.getIndexOfJitsiUser(id);
                    if (i !== -1) {
                        this.participants.splice(i, 1);
                    }
                }
            });

            this.jitsiConference.on(this.JitsiMeet.events.conference.PARTICIPANT_PROPERTY_CHANGED, (user, key, olVal, nVal) => {
                let i = this.getIndexOfJitsiUser(user.getId());
                if (key === 'screensharing') {
                    if (!this.user.admin) {
                        this.adminScreen = this.participants[i];
                        this.participants.splice(i, 1);
                    }
                } else {
                    switch (key) {
                        case 'firstName': this.participants[i].firstName = nVal;
                            break;
                        case 'lastName': this.participants[i].lastName = nVal;
                            break;
                        case 'mail': this.participants[i].mail = nVal;
                            break;
                        case 'micEnabled': this.participants[i].state.micEnabled = nVal === "true" ? true : false;
                            if (!olVal) this.micChangedSubject$.next({
                                user: this.participants[i], enabled: this.participants[i].state.micEnabled
                            });
                            break;
                        case 'videoEnabled': this.participants[i].state.videoEnabled = nVal === "true" ? true : false;
                            if (olVal === undefined) {
                                this.videoChangedSubject$.next({
                                    user: this.participants[i], enabled: this.participants[i].state.videoEnabled
                                });
                            }
                            break;
                        case 'handRaised': this.participants[i].state.handRaised = nVal === "true" ? true : false;
                            if (!olVal) this.handChangedSubject$.next({
                                user: this.participants[i], raised: this.participants[i].state.handRaised
                            });
                            break;
                        case 'screenSharingEnabled': this.participants[i].state.screenSharing = nVal === "true" ? true : false;
                            break;
                        case 'userId': this.participants[i].id = nVal;
                            break;
                        case 'admin': this.participants[i].admin = nVal === "true" ? true : false;
                            break;
                    }
                }
                // this.participantsSubject$.next({participants: this.participants, eventType: 'change', changedUser: this.participants[i]});
                // console.log('PARTICIPANT_PROPERTY_CHANGED', {user}, key, olVal, nVal);
            });

            this.jitsiConference.on(this.JitsiMeet.events.conference.CONFERENCE_JOINED, async () => {
                this.debug.sendDebugMessage({ event: 'conference joined', from: 'meeting service join room', lectureId });
                console.log('CONFERENCE_JOINED');
                let audioAccess = await this.JitsiMeet.mediaDevices.isDevicePermissionGranted('audio');
                let videoAccess = await this.JitsiMeet.mediaDevices.isDevicePermissionGranted('video');

                let devices = ['audio'];
                if(this.cameraAvailable) devices.push('video');

                this.JitsiMeet.createLocalTracks({ devices }).then(async (ms) => {
                    this.debug.sendDebugMessage({ event: 'media tracks created', from: 'meeting service join room', lectureId });
                    await this.jitsiConference.addTrack(ms[0]);
                    if(ms.length === 2) await this.jitsiConference.addTrack(ms[1]);
                    this.ownCameraStream = new MediaStream(ms.map(ms => ms.track));
                    this.ownVideoSubject$.next(this.ownCameraStream);
                    if (!micEnabled) this.disableMicrophone();
                    if (!videoEnabled) this.disableVideo();
                    this.meetingConnectionSubject$.next({ event: 'conference-joined' });
                }).catch(err => {
                    if(err.message === 'Permission denied') {
                        this.meetingConnectionSubject$.next({ event: 'no-media-permission' });
                    }
                    this.debug.sendErrorMessage({ event: 'media tracks creation failed', from: 'meeting service join room', lectureId, err });
                    console.log(err);
                });
            });

            this.jitsiConference.setDisplayName(this.user.firstName);
            this.jitsiConference.setLocalParticipantProperty('firstName', this.user.firstName);
            this.jitsiConference.setLocalParticipantProperty('lastName', this.user.lastName);
            this.jitsiConference.setLocalParticipantProperty('mail', this.user.userMail);
            this.jitsiConference.setLocalParticipantProperty('micEnabled', this.state.micEnabled);
            this.jitsiConference.setLocalParticipantProperty('videoEnabled', this.state.videoEnabled);
            this.jitsiConference.setLocalParticipantProperty('handRaised', this.state.handRaised);
            this.jitsiConference.setLocalParticipantProperty('screenSharingEnabled', this.state.screenSharing);
            this.jitsiConference.setLocalParticipantProperty('userId', this.user.userId);
            this.jitsiConference.setLocalParticipantProperty('admin', this.user.admin ? true : false);
            this.debug.sendDebugMessage({ event: 'local user props set', from: 'meeting service join room', lectureId });
            this.jitsiConference.join();
            this.debug.sendDebugMessage({ event: 'conference joined fully', from: 'meeting service join room', lectureId });

        });
    }

    private getIndexOfUser(userId): number {
        for (let i = 0; i < this.participants.length; i++) {
            if (this.participants[i].id === userId) return i;
        }
        return -1;
    }

    private getIndexOfJitsiUser(jitsiId): number {
        for (let i = 0; i < this.participants.length; i++) {
            if (this.participants[i].jitsiId === jitsiId) return i;
        }
        return -1;
    }

    public affirmPresence(affirmation: Affirmation, code: string) {
        this.socket.emit('confirm-affirmation', affirmation, code);
        this.debug.sendDebugMessage({ event: 'confirm-affirmation', from: 'meeting service affirmPresence', affirmation, code });
    }

    public sendMessage(content: string) {
        let message: ChatMessage = {
            from: this.user.userId,
            lectureId: this.lectureId,
            content
        };
        this.debug.sendDebugMessage({ event: 'send-message', from: 'meeting service sendMessage', message });
        this.socket.emit('send-message', this.lectureId, message);
    }

    public disableMicrophone(initial: boolean = false) {
        this.jitsiConference.getLocalTracks().filter(t => t.type === 'audio')[0].mute().then(() => {
            this.state.micEnabled = false;
            this.ownCameraStream.removeTrack(this.ownCameraStream.getAudioTracks()[0]);
            this.ownCameraStream.addTrack(this.jitsiConference.getLocalTracks().filter(t => t.type === 'audio')[0].track);
            this.socket.emit('mic-disabled');
            this.jitsiConference.setLocalParticipantProperty('micEnabled', this.state.micEnabled);
            this.debug.sendDebugMessage({ event: 'disable-mic', from: 'meeting service disableMicrophone' });
        }).catch(err => {
            console.log(err);
            this.debug.sendErrorMessage({ event: 'disable-mic-failed', from: 'meeting service disableMicrophone' });
        });
    }

    public enableMicrophone() {
        this.jitsiConference.getLocalTracks().filter(t => t.type === 'audio')[0].unmute().then(() => {
            this.state.micEnabled = true;
            this.ownCameraStream.removeTrack(this.ownCameraStream.getAudioTracks()[0]);
            this.ownCameraStream.addTrack(this.jitsiConference.getLocalTracks().filter(t => t.type === 'audio')[0].track);
            this.socket.emit('mic-enabled');
            this.jitsiConference.setLocalParticipantProperty('micEnabled', this.state.micEnabled);
            this.debug.sendDebugMessage({ event: 'enable-mic', from: 'meeting service enableMicrophone' });
        }).catch(err => {
            console.log(err);
            this.debug.sendErrorMessage({ event: 'enable-mic-failed', from: 'meeting service enableMicrophone', err });
        });
    }

    public enableVideo() {
        if(!this.cameraAvailable) return;
        this.jitsiConference.getLocalTracks().filter(t => t.type === 'video')[0].unmute().then(() => {
            this.state.videoEnabled = true;
            this.ownCameraStream.removeTrack(this.ownCameraStream.getVideoTracks()[0]);
            this.ownCameraStream.addTrack(this.jitsiConference.getLocalTracks().filter(t => t.type === 'video')[0].track);
            this.socket.emit('video-enabled');
            this.jitsiConference.setLocalParticipantProperty('videoEnabled', this.state.videoEnabled);
            this.debug.sendDebugMessage({ event: 'enable-video', from: 'meeting service enableVideo' });
        }).catch(err => {
            console.log(err);
            this.debug.sendErrorMessage({ event: 'enable-video-failed', from: 'meeting service enableVideo', err });
        });
    }

    public disableVideo(initial: boolean = false) {
        if(!this.cameraAvailable) return;
        this.jitsiConference.getLocalTracks().filter(t => t.type === 'video')[0].mute().then(() => {
            this.state.videoEnabled = false;
            this.ownCameraStream.removeTrack(this.ownCameraStream.getVideoTracks()[0]);
            this.ownCameraStream.addTrack(this.jitsiConference.getLocalTracks().filter(t => t.type === 'video')[0].track);
            this.socket.emit('video-disabled');
            this.jitsiConference.setLocalParticipantProperty('videoEnabled', this.state.videoEnabled);
            this.debug.sendDebugMessage({ event: 'disabled-video', from: 'meeting service disableVideo' });
        }).catch(err => {
            console.log(err)
            this.debug.sendErrorMessage({ event: 'disabled-video-failed', from: 'meeting service disableVideo', err });
        });
    }

    public raiseHand() {
        this.state.handRaised = true;
        this.socket.emit("raise-hand");
        this.debug.sendDebugMessage({ event: 'raise-hand', from: 'meeting service raiseHand' });
    }

    public lowerHand() {
        this.state.handRaised = false;
        this.debug.sendDebugMessage({ event: 'lower-hand', from: 'meeting service lowerHand' });
        this.socket.emit("lower-hand");
    }

    public async startScreenSharing() {
        try {
            this.setupScreenConnection(this.lectureId).then(() => {
                console.log("load screen tracks");
                return this.JitsiMeet.createLocalTracks({ devices: ["desktop"], desktopSharingFrameRate: { min: 30, max: 60 }, constraints: {
                        video: {
                            height: {
                                ideal: 360,
                                max: 360,
                                min: 240
                            }
                        }
                    }
                });
            }).then((ms) => {
                if (ms.length === 2) {
                    this.jitsiScreenConference.addTrack(ms[1]);
                }

                this.jitsiScreenConference.addTrack(ms[0]);
                this.ownScreenStream = new MediaStream(ms.map(ms => {
                    let t = (ms.track as MediaStreamTrack);
                    t.addEventListener('ended', () => {
                        this.stopScreenSharing();
                    });
                    return t;
                }));

                // this.ownVideoSubject$.next(this.ownScreenStream);
                this.ownScreenSharingStartedSubject.next(this.ownScreenStream);
                this.debug.sendDebugMessage({ event: 'start-screensharing', from: 'meeting service startScreenSharing' });
                this.socket.emit('start-screensharing', this.user);
                return true;
            }).catch(err => {
                this.debug.sendErrorMessage({ event: 'start-screensharing-failed', from: 'meeting service startScreenSharing', err });
                return false;
            });
        } catch (err) {
            this.debug.sendErrorMessage({ event: 'start-screensharing-failed', from: 'meeting service startScreenSharing', err });
            return false;
        }
    }

    public async stopScreenSharing() {
        return new Promise(async (res, rej) => {
            try {
                if(this.jitsiScreenConference.getLocalTracks().filter(t => t.type === 'video').length) {
                    await this.jitsiScreenConference.getLocalTracks().filter(t => t.type === 'video')[0].dispose();
                }
            } catch (err) {
                this.debug.sendErrorMessage({ event: 'stop-screensharing-fail', from: 'meeting service stopScreenSharing', err });
                rej();
            }
            try {
                if(this.jitsiScreenConference.getLocalTracks().filter(t => t.type === 'audio').length) {
                    await this.jitsiScreenConference.getLocalTracks().filter(t => t.type === 'audio')[0].dispose();
                }
            } catch (err) {
                this.debug.sendErrorMessage({ event: 'stop-screensharing-fail', from: 'meeting service stopScreenSharing', err });
                rej();
            }
            try {
                if (this.ownScreenStream?.getAudioTracks()[0]) {
                    this.jitsiScreenConference.removeTrack(this.ownScreenStream.getAudioTracks()[0]);
                }
            } catch (err) {
                console.log(err);
                this.debug.sendErrorMessage({ event: 'stop-screensharing-fail', from: 'meeting service stopScreenSharing', err });
                rej();
            }
            try {
                if (this.ownScreenStream?.getVideoTracks()[0]) {
                    this.jitsiScreenConference.removeTrack(this.ownScreenStream.getVideoTracks()[0]);
                }
            } catch (err) {
                console.log(err);
                this.debug.sendErrorMessage({ event: 'stop-screensharing-fail', from: 'meeting service stopScreenSharing', err });
                rej();
            }
            this.ownScreenStream = undefined;
            this.ownScreenSharingEndedSubject.next(true);
            this.ownVideoSubject$.next(this.ownCameraStream);
            this.debug.sendDebugMessage({ event: 'stop-screensharing', from: 'meeting service stopScreenSharing' });
            this.socket.emit('end-screensharing', this.user);
            this.jitsiScreenConnection.disconnect();
            res(true);
        });
    }

    public muteAllParticipants() {
        this.debug.sendDebugMessage({ event: 'muted-all', from: 'meeting service muteAllParticipants' });
        this.socket.emit('mute-all');
    }

    public leaveLecture() {
        this.debug.sendDebugMessage({ event: 'leave-lecture', from: 'meeting service leave lecture' });
        this.jitsiConnection.disconnect();

        if (this.user.admin) {
            this.jitsiScreenConnection?.disconnect();
        }
    }

    /*
        Utils
    */

    private async connectToNewUser(newUser: LectureUser, alreadyInRoom = false, newSocketId?: string) {

    }

    private setupWsListener() {
        this.socket.on('participant-connected', (newUser: User, socket_id, state: UserState) => {
            // let u: LectureUser = {
            //     id: newUser.userId ? newUser.userId : newUser._id,
            //     firstName: newUser.firstName,
            //     lastName: newUser.lastName,
            //     admin: newUser.admin,
            //     mail: newUser.mail,
            //     state: state
            // }
            // this.connectToNewUser(u, false, socket_id);
        });

        this.socket.on('already-in-room', (newUser: User, state: UserState) => {
            // let u: LectureUser = {
            //     id: newUser.userId ? newUser.userId : newUser._id,
            //     firstName: newUser.firstName,
            //     lastName: newUser.lastName,
            //     admin: newUser.admin,
            //     mail: newUser.mail,
            //     state: state
            // }
            // this.connectToNewUser(u, true);
        });

        this.socket.on('affirmation-request', (affirmationRequest: Affirmation) => {
            // console.log(affirmationRequest);
            this.debug.sendDebugMessage({ event: 'got-affirmation-request', from: 'meeting service setupWsListener()', affirmationRequest });
            this.affirmationSubject$.next(affirmationRequest);
        });

        this.socket.once('all-messages', (msgs: ChatMessage[]) => {
            this.debug.sendDebugMessage({ event: 'got-all-msgs', from: 'meeting service setupWsListener()', count: msgs.length });
            this.chatMessages = msgs;
            this.allMessagesSubject$.next(this.chatMessages);
        });

        this.socket.on('message-recieved', (message: ChatMessage) => {
            this.debug.sendDebugMessage({ event: 'got-msgs', from: 'meeting service setupWsListener()', message });
            this.chatMessages.push(message);
            console.log(this.chatMessages);
            this.messagesSubject$.next(this.chatMessages);
        });

        this.socket.on('user-enabled-video', (user: User) => {
            let uId = user.userId ? user.userId : user._id;
            for (let i = 0; i < this.participants.length; i++) {
                if (this.participants[i].id === uId) {
                    this.debug.sendDebugMessage({ event: 'got-user-enabled-video', from: 'meeting service setupWsListener()', rmUser: user });
                    this.participants[i].state.videoEnabled = true;
                    this.videoChangedSubject$.next({ enabled: true, user: this.participants[i] });
                    return;
                }
            }
            let reason = `user id ${uId} (${user.firstName} ${user.lastName}) not in local participants array`;
            this.debug.sendErrorMessage({ event: 'got-user-enabled-video-fail', from: 'meeting service setupWsListener()', rmUser: user, reason });
        });

        this.socket.on('user-disabled-video', (user: User) => {
            let uId = user.userId ? user.userId : user._id;
            for (let i = 0; i < this.participants.length; i++) {
                if (this.participants[i].id === uId) {
                    this.debug.sendDebugMessage({ event: 'got-user-disabled-video', from: 'meeting service setupWsListener()', rmUser: user });
                    this.participants[i].state.videoEnabled = false;
                    this.videoChangedSubject$.next({ enabled: false, user: this.participants[i] });
                    return;
                }
            }
            let reason = `user id ${uId} (${user.firstName} ${user.lastName}) not in local participants array`;
            this.debug.sendErrorMessage({ event: 'got-user-disabled-video-fail', from: 'meeting service setupWsListener()', rmUser: user, reason });
        });

        this.socket.on('user-enabled-mic', (user: User) => {
            let uId = user.userId ? user.userId : user._id;
            for (let i = 0; i < this.participants.length; i++) {
                if (this.participants[i].id === uId) {
                    this.participants[i].state.micEnabled = true;
                    this.micChangedSubject$.next({ enabled: true, user: this.participants[i] });
                    return;
                }
            }
            let reason = `user id ${uId} (${user.firstName} ${user.lastName}) not in local participants array`;
            this.debug.sendErrorMessage({ event: 'got-user-enabled-mic-fail', from: 'meeting service setupWsListener()', rmUser: user, reason });
        });

        this.socket.on('user-disabled-mic', (user: User) => {
            let uId = user.userId ? user.userId : user._id;
            for (let i = 0; i < this.participants.length; i++) {
                if (this.participants[i].id === uId) {
                    this.participants[i].state.micEnabled = false;
                    this.micChangedSubject$.next({ enabled: false, user: this.participants[i] });
                    this.debug.sendDebugMessage({ event: 'got-user-disabled-mic', from: 'meeting service setupWsListener()', rmUser: user });
                    return;
                }
            }
            let reason = `user id ${uId} (${user.firstName} ${user.lastName}) not in local participants array`;
            this.debug.sendErrorMessage({ event: 'got-user-disabled-mic-fail', from: 'meeting service setupWsListener()', rmUser: user, reason });
        });

        this.socket.on('screensharing-started', (user: User) => {
            let uId = user.userId ? user.userId : user._id;
            if (uId === this.user.userId) return;
            for (let i = 0; i < this.participants.length; i++) {
                if (this.participants[i].id === uId) {
                    this.participants[i].state.screenSharing = true;
                    this.adminScreenSharingSubject$.next({ enabled: true, user: this.participants[i] });
                    this.debug.sendDebugMessage({ event: 'got-screensharing-started', from: 'meeting service setupWsListener()', rmUser: user });
                    return;
                }
            }
            let reason = `user id ${uId} (${user.firstName} ${user.lastName}) not in local participants array`;
            this.debug.sendErrorMessage({ event: 'got-screensharing-startet-fail', from: 'meeting service setupWsListener()', rmUser: user, reason });
        });

        this.socket.on('screensharing-ended', (user: User) => {
            let uId = user.userId ? user.userId : user._id;
            if (uId === this.user.userId) return;
            for (let i = 0; i < this.participants.length; i++) {
                if (this.participants[i].id === uId) {
                    this.participants[i].state.screenSharing = false;
                    this.adminScreenSharingSubject$.next({ enabled: false, user: this.participants[i] });
                    this.debug.sendDebugMessage({ event: 'got-screensharing-ended', from: 'meeting service setupWsListener()', rmUser: user });
                    return;
                }
            }
            let reason = `user id ${uId} (${user.firstName} ${user.lastName}) not in local participants array`;
            this.debug.sendErrorMessage({ event: 'got-screensharing-ended-fail', from: 'meeting service setupWsListener()', rmUser: user, reason });
        });

        this.socket.on('user-raised-hand', (user: User) => {
            let uId = user.userId ? user.userId : user._id;
            for (let i = 0; i < this.participants.length; i++) {
                if (this.participants[i].id === uId) {
                    this.participants[i].state.handRaised = true;
                    this.handRaisedSubject$.next({ user: this.participants[i], raised: true });
                    this.handChangedSubject$.next({ user: this.participants[i], raised: true });
                    return;
                }
            }
            let reason = `user id ${uId} (${user.firstName} ${user.lastName}) not in local participants array`;
            this.debug.sendErrorMessage({ event: 'got-user-raisde-hand-fail', from: 'meeting service setupWsListener()', rmUser: user, reason });
        });

        this.socket.on('user-lowered-hand', (user: User) => {
            let uId = user.userId ? user.userId : user._id;
            for (let i = 0; i < this.participants.length; i++) {
                if (this.participants[i].id === uId) {
                    this.participants[i].state.handRaised = false;
                    this.handRaisedSubject$.next({ user: this.participants[i], raised: false });
                    this.handChangedSubject$.next({ user: this.participants[i], raised: false });
                    return;
                }
            }
            let reason = `user id ${uId} (${user.firstName} ${user.lastName}) not in local participants array`;
            this.debug.sendErrorMessage({ event: 'got-user-lowered-hand-fail', from: 'meeting service setupWsListener()', rmUser: user, reason });
        });

        this.socket.on('got-muted', (user) => {
            if (this.state.micEnabled && user.id !== this.user.userId) {
                this.gotMutedSubject.next(true);
                this.disableMicrophone();
            }
        });

        this.socket.on('error', (error: string) => {
            console.log(error);
            this.errorSubject$.next(error);
        });
    }

}
