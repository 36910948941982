import { AfterViewInit, ApplicationRef, Component, ComponentFactoryResolver, ElementRef, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { Affirmation, CacheUser, ChatMessage, LectureUser, User } from '../shared/interfaces';
import { LectureRtcService } from '../shared/lecture-rtc.service';
import { LectureWsService } from '../shared/lecture-ws.service';
import { LectureVideoGridComponent } from '../lecture-video-grid/lecture-video-grid.component';
import { ComponentRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LectureMeetingService } from '../shared/lecture-meeting.service';
import { LectureService } from '../shared/lecture.service';
import { NgxSpinnerService } from 'ngx-spinner';

let JitsiMeetJS;

@Component({
    selector: 'app-lecture',
    templateUrl: './lecture.component.html',
    styleUrls: ['./lecture.component.scss']
})
export class LectureComponent implements OnInit, OnDestroy, AfterViewInit {

    public microphoneEnabled: boolean = true;
    public handRaised: boolean = false;
    public videoEnabled: boolean = true;

    public lectureId: string;
    public ownVideoMinimized: boolean = false;
    public showChatPanel: boolean = false;
    public showParticipantsPanel: boolean = false;
    public showVideoGrid: boolean = false;
    public streamScreen: boolean = false;
    public browserNotSupported: boolean = false;
    public user: User;
    public mediaStreams: MediaStream[] = [];
    public adminScreenStreamActive: boolean = false;
    public adminConnected: boolean = false;

    private initialChoiceMadeDone: boolean = false;
    private jitsiConnectionEstablished: boolean = false;
    private jitsiConferenceJoined: boolean = false;

    public initialDeviceSelectionForm: FormGroup = this.fb.group({
        mic: [true],
        camera: [true]
    });

    public affirmationCodeForm: FormGroup = this.fb.group({
        code: ['']
    });
    public currAffirmation: Affirmation;
    public affirmationCodeWrong: boolean = false;

    public videoGridPortalWindowInstance;
    public videoGridPortalOutlet;
    public videoGridPortalComponentInstance;

    public availableDevices: InputDeviceInfo | MediaDeviceInfo[];
    public cameraAvailable: boolean = false;
    public microphoneAvailable: boolean = false;

    private msgSubscription: Subscription;
    private ownVideoSubscription: Subscription;
    private adminVideoSubscription: Subscription;
    private adminScreenSharingSubscription: Subscription;
    private screenSharingEndedSubscription: Subscription;
    private screenSharingStartedSubscription: Subscription;
    private errorSubscription: Subscription;
    private errorSubscriptionRTC: Subscription;
    private alreadyInRoomSubscription: Subscription;
    private allMediaStreamSubscription: Subscription;
    private newPartSubscription: Subscription;
    private newVideoSubscription: Subscription;
    private handRaisedSubscription: Subscription;
    private userLeftSubscription: Subscription;
    private affirmationRequestSubscription: Subscription;
    private adminMsgsSubscription: Subscription;
    private initialized: boolean = false;
    private activeToasts: { userId: string, toast: ActiveToast<any> }[] = [];

    private adminStream: MediaStream;
    private adminScreenStream: MediaStream;
    public browser: "chrome"|"firefox"|"safari"|"opera"|"edge"|"GSA"|undefined;


    @ViewChild('mainVideoElement') mainVideoElement: ElementRef;
    @ViewChild('ownVideoElement') ownVideoElement: ElementRef;
    @ViewChild('adminCameraVideoElement') adminCameraVideoElement: ElementRef;

    @ViewChild('wrongBrowserSwal')
    public readonly wrongBrowserSwal!: SwalComponent;
    @ViewChild('initialSwal')
    public readonly initialSwal!: SwalComponent;
    @ViewChild('affirmationSwal')
    public readonly affirmationSwal!: SwalComponent;
    @ViewChild('notStartedSwal')
    public readonly notStartedSwal!: SwalComponent;
    @ViewChild('permissionsErrorSwal')
    public readonly permissionsErrorSwal!: SwalComponent;

    constructor(private authService: AuthService, private toastr: ToastrService, private _location: Location,
        public readonly swalTargets: SwalPortalTargets, private injector: Injector, private spinner: NgxSpinnerService,
        private componentFactoryResolver: ComponentFactoryResolver, private fb: FormBuilder,
        private applicationRef: ApplicationRef, private route: ActivatedRoute, private router: Router,
        private meetingService: LectureMeetingService, private lectureService: LectureService) {
        this.authService.getCurrentUserSecure().subscribe((user: any) => {
            this.user = user;
            this.meetingService.initJitsi();
            this.setupConnections();
            this.spinner.show();
        });
    }

    ngOnInit(): void {
        if(navigator.userAgent.match(/GSA/i)){
            this.browser = "GSA";
            this.browserNotSupported = true;
        } else if(navigator.userAgent.match(/chrome|chromium|crios/i)){
            this.browser = "chrome";
        } else if(navigator.userAgent.match(/firefox|fxios/i)){
            this.browser = "firefox";
        } else if(navigator.userAgent.match(/safari/i)){
            this.browser = "safari";
        } else if(navigator.userAgent.match(/opr\//i)){
            this.browser = "opera";
        } else if(navigator.userAgent.match(/edg/i)){
            this.browser = "edge";
        }
        if (navigator.mediaDevices.getUserMedia === undefined) {
            this.browserNotSupported = true;
        }
    }

    ngAfterViewInit(): void {
        this.lectureId = this.route.snapshot.params["id"];
        if (!this.lectureId) this.notStartedSwal.fire();
        if (!this.initialized) {
            this.lectureService.checkIfLectureIsOpen(this.lectureId).toPromise().then(d => {
                if (d) {
                    if (this.browserNotSupported) {
                        this.wrongBrowserSwal.fire();
                    } else {
                        navigator.mediaDevices.enumerateDevices().then(availableDevices => {
                            this.cameraAvailable = availableDevices.filter(d => d.kind === "videoinput").length > 0;
                            this.microphoneAvailable = availableDevices.filter(d => d.kind === "audioinput").length > 0;
                            this.initialSwal.fire();
                        });
                    }
                    this.initialized = true;
                } else {
                    this.notStartedSwal.fire();
                }
            }).catch(err => { });
        }
    }

    setup() {

    }

    initialChoiceMade() {
        this.videoEnabled = this.initialDeviceSelectionForm.value.camera && this.cameraAvailable;
        this.microphoneEnabled = this.initialDeviceSelectionForm.value.mic && this.microphoneAvailable;
        this.initialChoiceMadeDone = true;
        if(this.jitsiConnectionEstablished) {
            this.meetingService.joinRoom(this.lectureId, this.videoEnabled, this.microphoneEnabled, this.cameraAvailable);
        }
    }

    setupConnections() {
        this.alreadyInRoomSubscription = this.meetingService.alreadyInRoomParticipantsObservable$.subscribe(data => {
            this.toastr.info(data.firstName + ' ' + data.lastName + ' ist bereits da');
        });

        this.meetingService.meetingConnectionObservable$.subscribe((data) => {
            if (data.event === 'connection-established') {
                this.jitsiConnectionEstablished = true;
                if(this.initialChoiceMadeDone) {
                    this.meetingService.joinRoom(this.lectureId, this.videoEnabled, this.microphoneEnabled, this.cameraAvailable);
                }
            } else if (data.event === 'conference-joined') {
                this.spinner.hide();
            } else if (data.event === 'no-media-permission') {
                this.meetingService.leaveLecture();
                this.permissionsErrorSwal.fire();
            }
        });

        this.newPartSubscription = this.meetingService.newParticipantsObservable$.subscribe((newParticipant: LectureUser) => {
            if (newParticipant.admin === true) this.adminConnected = true;
            this.toastr.info(newParticipant.firstName + ' ' + newParticipant.lastName + ' ist beigetreten');
        });

        this.allMediaStreamSubscription = this.meetingService.participantsObservable$.subscribe(data => {
            this.mediaStreams = data.participants.filter(u => u.stream).map(u => u.stream);
        });
        // await this.meetingService.setupConnection(this.lectureId, this.videoEnabled, this.microphoneEnabled, this.handRaised, this.streamScreen);
        if (this.user.admin) {
            this.adminConnected = true;
            // this.videoService.connectVideo(this.microphoneEnabled, this.videoEnabled);
            this.adminVideoSubscription = this.meetingService.ownVideoObservable$.subscribe((data) => {
                console.log('own video admin sub');
                this.adminStream = data;
                this.mainVideoElement.nativeElement.srcObject = data;
                this.mainVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                    this.mainVideoElement.nativeElement.play();
                });
            }, (err) => {
                console.log(err);
            });
            this.screenSharingEndedSubscription = this.meetingService.ownScreenSharingEndedObservable$.subscribe((data) => {
                this.deactivateAdminScreenSharingLayout();
                this.streamScreen = false;
                this.adminScreenStreamActive = false;
            });
            this.screenSharingStartedSubscription = this.meetingService.ownScreenSharingStartedObservable$.subscribe((data) => {
                this.streamScreen = true;
                this.adminScreenStream = data;
                this.activateAdminScreenSharingLayout();
            });

            this.handRaisedSubscription = this.meetingService.handChangedObservable$.subscribe((data) => {
                console.log(data);
                if (data.raised) {
                    this.activeToasts.push({
                        userId: data.user.id,
                        toast: this.toastr.success(data.user.firstName + ' ' + data.user.lastName + ' möchte etwas sagen!', 'Meldung', {
                            disableTimeOut: true
                        })
                    });
                } else {
                    let foundI;
                    for (let i = 0; i < this.activeToasts.length; i++) {
                        if (this.activeToasts[i].userId === data.user.id) {
                            foundI = i;
                        }
                    }
                    if (foundI !== undefined) {
                        this.toastr.remove(this.activeToasts[foundI].toast.toastId);
                        this.activeToasts.splice(foundI, 1);
                    }
                }
                console.log(this.activeToasts);
            });
            this.adminMsgsSubscription = this.meetingService.adminMsgObservable$.subscribe(msg => {
                this.toastr.success(msg, 'Spezial Nachricht', { disableTimeOut: true, tapToDismiss: true });
            });
        } else {
            // this.videoService.connectVideo(this.microphoneEnabled && this.microphoneAvailable, this.videoEnabled && this.microphoneAvailable);
            this.ownVideoSubscription = this.meetingService.ownVideoObservable$.subscribe((stream) => {
                console.log('own video sub');
                this.ownVideoElement.nativeElement.srcObject = stream;
                this.ownVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                    this.ownVideoElement.nativeElement.play();
                });
            });

            this.adminVideoSubscription = this.meetingService.adminVideoObservable$.subscribe((data) => {
                // console.log('admin video sub', data);
                this.adminConnected = true;
                this.adminStream = data;
                try {
                    if (this.adminScreenStreamActive) {
                        if (this.adminCameraVideoElement.nativeElement.src || this.adminCameraVideoElement.nativeElement.srcObject) {
                            // this.adminCameraVideoElement.nativeElement.pause();
                            this.adminCameraVideoElement.nativeElement.removeAttribute('src');
                            this.adminCameraVideoElement.nativeElement.removeAttribute('srcObject');
                            this.adminCameraVideoElement.nativeElement.load();
                        }

                        this.adminCameraVideoElement.nativeElement.srcObject = this.adminStream;
                        this.adminCameraVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                            this.adminCameraVideoElement.nativeElement.play();
                        });
                    } else {
                        if (this.mainVideoElement.nativeElement.src || this.mainVideoElement.nativeElement.srcObject) {
                            // this.mainVideoElement.nativeElement.pause();
                            this.mainVideoElement.nativeElement.removeAttribute('src');
                            this.mainVideoElement.nativeElement.removeAttribute('srcObject');
                            this.mainVideoElement.nativeElement.load();
                        }

                        this.mainVideoElement.nativeElement.srcObject = data;
                        this.mainVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                            // console.log("admin vid play");
                            this.mainVideoElement.nativeElement.play();
                        });
                    }
                } catch { }
            });

            this.adminScreenSharingSubscription = this.meetingService.adminScreenSharingObservable$.subscribe(data => {
                if (data.enabled !== true) {
                    this.deactivateAdminScreenSharingLayout();
                }
            });

            this.meetingService.screenSharingVideoObservable$.subscribe(screenSharingStream => {
                this.adminScreenStream = screenSharingStream;
                this.activateAdminScreenSharingLayout();
            });
        }

        this.affirmationRequestSubscription = this.meetingService.affirmationObservable$.subscribe((aff) => {
            this.currAffirmation = { ...aff };
            this.affirmationSwal.fire();
        });

        this.errorSubscription = this.meetingService.errorObservable$.subscribe((err: any) => {
            if (err === 'lecture-not-started') {
                this.notStartedSwal.fire();
            } else if (err.code && err.code === 8) {
                this.toastr.error("Sollte an deinem Gerät eine Kamera und Mikrofon vorhanden sein, schließe bitte ALLE Browser-Fenster und versuch es erneut.", "Keine Kamera und kein Mikrofon verfügbar!", {
                    disableTimeOut: true,
                    tapToDismiss: true
                });
            } else {
                this.toastr.error(err.message ? err.message : err, "Ein Fehler ist aufgetreten", {
                    disableTimeOut: true,
                    tapToDismiss: true
                });
            }
        });

        this.userLeftSubscription = this.meetingService.leftParticipantsObservable$.subscribe((leftUser) => {
            if (leftUser.admin) {
                if (this.adminScreenStream || this.adminScreenStreamActive) {
                    this.deactivateAdminScreenSharingLayout();
                }
                this.mainVideoElement.nativeElement.pause();
                this.mainVideoElement.nativeElement.removeAttribute('src');
                this.mainVideoElement.nativeElement.removeAttribute('srcObject');
                this.mainVideoElement.nativeElement.load();
                this.adminConnected = false;
            }
            // this.meetingService.closePeerToUser(leftUser);
            this.toastr.info(leftUser.firstName + ' ' + leftUser.lastName + ' hat den Unterricht verlassen');
        });

        this.meetingService.gotMutedObservable$.subscribe(() => {
            this.microphoneEnabled = false;
        });
    }

    ngOnDestroy(): void {
        if (this.allMediaStreamSubscription) this.allMediaStreamSubscription.unsubscribe();
        if (this.msgSubscription) this.msgSubscription.unsubscribe();
        if (this.ownVideoSubscription) this.ownVideoSubscription.unsubscribe();
        if (this.adminVideoSubscription) this.adminVideoSubscription.unsubscribe();
        if (this.screenSharingEndedSubscription) this.screenSharingEndedSubscription.unsubscribe();
        if (this.errorSubscription) this.errorSubscription.unsubscribe();
        if (this.alreadyInRoomSubscription) this.alreadyInRoomSubscription.unsubscribe();
        if (this.newPartSubscription) this.newPartSubscription.unsubscribe();
        if (this.userLeftSubscription) this.userLeftSubscription.unsubscribe();
        if (this.newVideoSubscription) this.newVideoSubscription.unsubscribe();
        if (this.affirmationRequestSubscription) this.affirmationRequestSubscription.unsubscribe();
        if (this.screenSharingStartedSubscription) this.screenSharingStartedSubscription.unsubscribe();
        if (this.handRaisedSubscription) this.handRaisedSubscription.unsubscribe();
        if (this.errorSubscriptionRTC) this.errorSubscriptionRTC.unsubscribe();
    }

    toggleMicrophone() {
        this.microphoneEnabled = !this.microphoneEnabled;
        if (!this.microphoneEnabled) {
            this.meetingService.disableMicrophone();
        } else {
            this.meetingService.enableMicrophone();
        }
    }

    toggleHand() {
        this.handRaised = !this.handRaised;
        if (this.handRaised) {
            this.meetingService.raiseHand();
        } else {
            this.meetingService.lowerHand();
        }
    }

    toggleScreenSharing() {
        console.log(this.streamScreen);
        this.streamScreen = !this.streamScreen;
        if (this.streamScreen) {
            this.meetingService.startScreenSharing().then((succ: boolean) => {
                this.streamScreen = succ;
            });
        } else {
            this.meetingService.stopScreenSharing().then(() => {
                this.streamScreen = false;
            });;
        }
    }

    toggleVideo() {
        this.videoEnabled = !this.videoEnabled;
        if (this.videoEnabled) {
            this.meetingService.enableVideo();
        } else {
            this.meetingService.disableVideo();
        }
    }

    toggleOwnVideoPreview(hide: boolean = true) {
        this.ownVideoMinimized = hide;
    }

    muteAllParticipants() {
        this.meetingService.muteAllParticipants();
    }

    goBack() {
        if (!this.user) this.user = (this.authService.getCurrentUser() as any);
        location.href = this.user.admin ? '/admin' : '/';
    }

    activateAdminScreenSharingLayout() {
        this.adminScreenStreamActive = true;
        if (this.user.admin) {
            this.ownVideoMinimized = false;
            if (this.mainVideoElement.nativeElement.src || this.mainVideoElement.nativeElement.srcObject) {
                this.mainVideoElement.nativeElement.pause();
                this.mainVideoElement.nativeElement.removeAttribute('src');
                this.mainVideoElement.nativeElement.removeAttribute('srcObject');
                this.mainVideoElement.nativeElement.load();
            }

            this.mainVideoElement.nativeElement.srcObject = this.adminScreenStream;
            this.mainVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                this.mainVideoElement.nativeElement.play();
            });
            if (this.ownVideoElement.nativeElement.src || this.ownVideoElement.nativeElement.srcObject) {
                this.ownVideoElement.nativeElement.pause();
                this.ownVideoElement.nativeElement.removeAttribute('src');
                this.ownVideoElement.nativeElement.removeAttribute('srcObject');
                this.ownVideoElement.nativeElement.load();
            }

            this.ownVideoElement.nativeElement.srcObject = this.adminStream;
            this.ownVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                this.ownVideoElement.nativeElement.play();
            });
        } else {
            if (this.mainVideoElement.nativeElement.src || this.mainVideoElement.nativeElement.srcObject) {
                this.mainVideoElement.nativeElement.pause();
                this.mainVideoElement.nativeElement.removeAttribute('src');
                this.mainVideoElement.nativeElement.removeAttribute('srcObject');
                this.mainVideoElement.nativeElement.load();
            }

            this.mainVideoElement.nativeElement.srcObject = this.adminScreenStream;
            this.mainVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                this.mainVideoElement.nativeElement.play();
            });
            if (this.adminCameraVideoElement.nativeElement.src || this.adminCameraVideoElement.nativeElement.srcObject) {
                this.adminCameraVideoElement.nativeElement.pause();
                this.adminCameraVideoElement.nativeElement.removeAttribute('src');
                this.adminCameraVideoElement.nativeElement.removeAttribute('srcObject');
                this.adminCameraVideoElement.nativeElement.load();
            }

            this.adminCameraVideoElement.nativeElement.srcObject = this.adminStream;
            this.adminCameraVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                this.adminCameraVideoElement.nativeElement.play();
            });
        }
    }

    deactivateAdminScreenSharingLayout() {
        if (this.user.admin) {
            if (this.mainVideoElement.nativeElement.src || this.mainVideoElement.nativeElement.srcObject) {
                this.mainVideoElement.nativeElement.pause();
                this.mainVideoElement.nativeElement.removeAttribute('src');
                this.mainVideoElement.nativeElement.removeAttribute('srcObject');
                this.mainVideoElement.nativeElement.load();
                this.mainVideoElement.nativeElement.src = null;
                this.mainVideoElement.nativeElement.srcObject = null;
            }

            this.mainVideoElement.nativeElement.srcObject = this.adminStream;
            this.mainVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                this.mainVideoElement.nativeElement.play();
            });

            if (this.ownVideoElement?.nativeElement?.src || this.ownVideoElement?.nativeElement?.srcObject) {
                this.ownVideoElement.nativeElement.pause();
                this.ownVideoElement.nativeElement.removeAttribute('src');
                this.ownVideoElement.nativeElement.removeAttribute('srcObject');
                this.ownVideoElement.nativeElement.src = null;
                this.ownVideoElement.nativeElement.srcObject = null;
                this.ownVideoElement.nativeElement.load();
            }
        } else {
            if (this.mainVideoElement.nativeElement.src || this.mainVideoElement.nativeElement.srcObject) {
                this.mainVideoElement.nativeElement.pause();
                this.mainVideoElement.nativeElement.removeAttribute('src');
                this.mainVideoElement.nativeElement.removeAttribute('srcObject');
                this.mainVideoElement.nativeElement.load();
                this.mainVideoElement.nativeElement.src = null;
                this.mainVideoElement.nativeElement.srcObject = null;
            }

            this.mainVideoElement.nativeElement.srcObject = this.adminStream;
            this.mainVideoElement.nativeElement.addEventListener('loadedmetadata', () => {
                this.mainVideoElement.nativeElement.play();
            });

            if (this.adminCameraVideoElement.nativeElement.src || this.adminCameraVideoElement.nativeElement.srcObject) {
                this.adminCameraVideoElement.nativeElement.pause();
                this.adminCameraVideoElement.nativeElement.removeAttribute('src');
                this.adminCameraVideoElement.nativeElement.removeAttribute('srcObject');
                this.adminCameraVideoElement.nativeElement.src = null;
                this.adminCameraVideoElement.nativeElement.srcObject = null;
                this.adminCameraVideoElement.nativeElement.load();
            }
        }
        this.adminScreenStreamActive = false;
    }

    openVideoGridExternalWindow() {
        this.videoGridPortalWindowInstance = window.open('', 'Fahrschüler Videos', '');
        // if the "target" window was just opened, change its url
        if (this.videoGridPortalWindowInstance.location.href === 'about:blank') {
            this.videoGridPortalWindowInstance.location.href = 'assets/windows/video-grid.html';
        }

        this.videoGridPortalWindowInstance.document.body.innerText = '';

        document.querySelectorAll('style').forEach(htmlElement => {
            this.videoGridPortalWindowInstance.document.head.appendChild(htmlElement.cloneNode(true));
        });
        // Copy stylesheet link from parent window
        const styleSheetElement = document.createElement('link');
        document.querySelectorAll('link').forEach(htmlElement => {
            if (htmlElement.rel === 'stylesheet' && htmlElement.href) {
                const absoluteUrl = new URL(htmlElement.href).href;
                styleSheetElement.rel = 'stylesheet';
                styleSheetElement.href = absoluteUrl;
            }
        });
        this.videoGridPortalWindowInstance.document.head.appendChild(styleSheetElement);
        this.videoGridPortalOutlet = new DomPortalOutlet(this.videoGridPortalWindowInstance.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);
        this.videoGridPortalWindowInstance.focus();
        const containerPortal = new ComponentPortal(LectureVideoGridComponent);
        const containerRef: ComponentRef<LectureVideoGridComponent> = this.videoGridPortalOutlet.attach(containerPortal);
        this.videoGridPortalComponentInstance = containerRef.instance;
    }

    confirmPresence() {
        console.log(this.currAffirmation, this.affirmationCodeForm.value);
        if (this.currAffirmation.code === this.affirmationCodeForm.value.code) {
            this.affirmationCodeWrong = false;
            this.meetingService.affirmPresence(this.currAffirmation, this.affirmationCodeForm.value.code);
            this.affirmationCodeForm.value.code = '';
        } else {
            this.affirmationCodeWrong = true;
            this.affirmationSwal.fire();
        }
    }

    leaveLecture() {
        this.meetingService.leaveLecture();
        location.href = this.user.admin ? '/admin' : '/';
    }
}
