import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { LectureUser, User } from '../shared/interfaces';
import { LectureMeetingService } from '../shared/lecture-meeting.service';
import { LectureRtcService } from '../shared/lecture-rtc.service';
import { LectureWsService } from '../shared/lecture-ws.service';

@Component({
    selector: 'app-lecture-participant-list',
    templateUrl: './lecture-participant-list.component.html',
    styleUrls: ['./lecture-participant-list.component.scss']
})
export class LectureParticipantListComponent implements OnInit, OnDestroy {

    @Input() show: boolean;
    @Output() showChange = new EventEmitter<boolean>();

    public participants: LectureUser[] = [];

    private allpartSubscription: Subscription;
    private partSubscription: Subscription;
    private newVideoSubscription: Subscription;
    private handChangedSubscription: Subscription;
    private micChangedSubscription: Subscription;
    private videoChangedSubscription: Subscription;

    constructor(private meetingService: LectureMeetingService) { }

    ngOnInit(): void {

        this.allpartSubscription = this.meetingService.allParticipantsStatesObservable$.subscribe(data => {
            this.participants = data;
        });

        this.micChangedSubscription = this.meetingService.micChangedObservable$.subscribe((data) => {
            for(let j = 0; j < this.participants.length; j++) {
                if(data.user.id === this.participants[j].id) {
                    this.participants[j].state.micEnabled = data.enabled;
                }
            }
        });

        this.videoChangedSubscription = this.meetingService.videoChangedObservable$.subscribe((data) => {
            for(let j = 0; j < this.participants.length; j++) {
                if(data.user.id === this.participants[j].id) {
                    this.participants[j].state.videoEnabled = data.enabled;
                }
            }
        });

        this.handChangedSubscription = this.meetingService.handChangedObservable$.subscribe((data) => {
            for(let j = 0; j < this.participants.length; j++) {
                if(data.user.id === this.participants[j].id) {
                    this.participants[j].state.handRaised = data.raised;
                }
            }
        });
    }

    ngOnDestroy(): void {
        if(this.allpartSubscription) this.allpartSubscription.unsubscribe();
        if(this.partSubscription) this.partSubscription.unsubscribe();
        if(this.newVideoSubscription) this.newVideoSubscription.unsubscribe();
        if(this.micChangedSubscription) this.micChangedSubscription.unsubscribe();
        if(this.videoChangedSubscription) this.videoChangedSubscription.unsubscribe();
        if(this.handChangedSubscription) this.handChangedSubscription.unsubscribe();
    }
}
