import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  tabs = {
    future: true,
    past: false,
    all: false
  };

  constructor() { }

  ngOnInit(): void {
  }

  changeTab(name: string) {
    if (name === 'future') {
      this.tabs.future = true;
      this.tabs.past = false;
      this.tabs.all = false;
    }
    if (name === 'past') {
      this.tabs.future = false;
      this.tabs.past = true;
      this.tabs.all = false;
    }
    if (name === 'all') {
      this.tabs.future = false;
      this.tabs.past = false;
      this.tabs.all = true;
    }
  }

}
