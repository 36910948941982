import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LectureUser, User } from '../shared/interfaces';
import { LectureMeetingService } from '../shared/lecture-meeting.service';
import { LectureWsService } from '../shared/lecture-ws.service';

@Component({
    selector: 'app-lecture-participant-video',
    templateUrl: './lecture-participant-video.component.html',
    styleUrls: ['./lecture-participant-video.component.scss']
})
export class LectureParticipantVideoComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input('videoStream')
    set videoStream(videoStream: MediaStream) {
        this._stream = videoStream;
    };
    @Input('width') width: string;
    @Input('height') height: string;
    @Input('user') user: LectureUser;
    @ViewChild('videoElement') videoElement: ElementRef;

    private _stream: MediaStream;
    private handChangedSubscription: Subscription;
    private micChangedSubscription: Subscription;
    private videoChangedSubscription: Subscription;

    showAvatar: boolean = true;
    micEnabled: boolean = false;
    handRaised: boolean = false;

    constructor(private meetingService: LectureMeetingService) {}

    ngOnInit(): void {
        this.showAvatar = !this.user.state.videoEnabled ?? false;
        this.micEnabled = this.user.state.micEnabled ?? false;
        this.handRaised = this.user.state.handRaised ?? false;
    }

    ngAfterViewInit(): void {
        this.changeVideo();
    }

    ngOnChanges(): void {
        this.changeVideo();
    }

    changeVideo() {
        if(this.videoElement && this._stream) {
            this.videoElement.nativeElement.srcObject = this._stream;
            this.videoElement.nativeElement.addEventListener('loadedmetadata', () => {
                this.videoElement.nativeElement.play();
            });
        }
    }

    ngOnDestroy(): void {
        if(this.micChangedSubscription) this.micChangedSubscription.unsubscribe();
        if(this.videoChangedSubscription) this.videoChangedSubscription.unsubscribe();
        if(this.handChangedSubscription) this.handChangedSubscription.unsubscribe();
    }

}
