import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    mail: ['', [Validators.required, Validators.email]],
    // tslint:disable-next-line:max-line-length
    password: ['', Validators.required]
  });

  loginError = false;

  constructor(private fb: FormBuilder, private authService: AuthService,private router: Router) { }

  ngOnInit(): void {
  }

  submitLogin() {
    this.authService.validate(this.loginForm.value.mail, this.loginForm.value.password).subscribe((data: any) => {
      this.authService.setUserInfo(data.user);
      if(data.user.admin) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/']);
      }
    }, err => {
      console.log(err);
      this.loginError = err.error ? err.error.error : 'Es ist ein Fehler aufgetreten!';
    });
  }
}
