import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { UserService } from '../shared/user.service';
import { CacheUser, User } from '../shared/interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  tabs = {
    locked: true,
    all: false
  };

  thisMoment = moment;

  allUser: User[];
  lockedUser: User[];
  showOldUnlocked = false;

  userName = '';

  constructor(private authService: AuthService, private userService: UserService) { }

  ngOnInit(): void {
    this.userName = (this.authService.getCurrentUser() as CacheUser).firstName;
    this.loadData();
  }

  loadData() {
    this.userService.getAll().subscribe((data: any) => {
      this.allUser = [... data];
    }, (err) => {
      console.log(err);
    });
    this.userService.getAllLocked().subscribe((data: any) => {
      if (this.showOldUnlocked === false) {
        const today = moment().subtract(7, 'days');
        this.lockedUser = [... data.filter((l) => {
          const d = moment(l.created_at);
          if (d.isSameOrAfter(today, 'D')) {
            return true;
          } else {
            return false;
          }
        })];
      } else {
        this.lockedUser = [... data];
      }
    }, (err) => {
      console.log(err);
    });
  }

  allLockedToggleClicked() {
    this.showOldUnlocked = !this.showOldUnlocked;
    this.loadData();
  }

  lockUser(id) {
    this.userService.lockUser(id).subscribe((data) => {
      // console.log(data);
      this.loadData();
    }, (err) => {
      console.log(err);
    });
  }

  unlockUser(id) {
    this.userService.unlockUser(id).subscribe((data) => {
      // console.log(data);
      this.loadData();
    }, (err) => {
      console.log(err);
    });
  }

  changeTab(name: string) {
    if (name === 'locked') {
      this.tabs.locked = true;
      this.tabs.all = false;
    }
    if (name === 'all') {
      this.tabs.locked = false;
      this.tabs.all = true;
    }
  }
}
