import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { DebugService } from "src/app/shared/debug.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, private debug: DebugService) {}

  handleError(error: Error) {

    this.zone.run(() =>{
        this.debug.sendErrorMessage(error);
        console.log(error);
    });

    // console.error("Error from global error handler", error);
  }
}
