<div class="form-row">
    <div class="form-group col-md-6">
        <div class="form-group hidden">
            <div class="input-group">
                <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                    outsideDays="hidden" [startDate]="fromFilter!">
                <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                    </span>
                </ng-template>
            </div>
        </div>
        <div class="input-group">
            <div class="input-group-prepend" style="background-color:rgb(255, 255, 255);">
                <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button">Von:
                </button>
            </div>
            <input #dpfromFilter class="form-control" placeholder="yyyy-mm-dd" name="dpfromFilter"
                (click)="datepicker.toggle()" [value]="formatter.format(fromFilter)"
                (input)="fromFilter = validateInput(fromFilter, dpfromFilter.value)">
        </div>
    </div>
    <div class="form-group col-md-6">
        <div class="input-group">
            <div class="input-group-prepend" style="background-color:rgb(255, 255, 255);">
                <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button">Bis:
                </button>
            </div>
            <input #dptoFilter class="form-control" placeholder="yyyy-mm-dd" name="dptoFilter"
                (click)="datepicker.toggle()" [value]="formatter.format(toFilter)"
                (input)="toFilter = validateInput(toFilter, dptoFilter.value)">
        </div>
    </div>
</div>

<div class="alert alert-success" role="alert" *ngIf="showSuccessMsg">
    Anmeldung erfolgreich!
</div>

<div class="alert alert-danger" role="alert" *ngIf="showErrorMsg">
    {{errorMsg}}
</div>

<div class="accordion" id="accordionExample" *ngIf="!registrationsOnly">
    <div class="card" *ngIf="lectures.length === 0">
        <div class="card-header d-flex justify-content-between">
            <h6 class="m-0 p-0 flex-fill">Keine {{registrationsOnly ? 'Anmeldungen' : 'Termine'}} vorhanden!</h6>
        </div>
    </div>
    <div class="card" *ngFor="let lecture of lectures">
        <div class="card-header d-flex justify-content-between"
            [ngClass]="getColorClass((lecture.seats - lecture.registrations.length), lecture.courseReady)"
            (click)="lecture.expand = lecture.expand ? false : true">
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{lecture.title}}
                {{ lecture.seats - lecture.registrations.length <= 0 ? '(Ausgebucht)' : '' }}
                {{lecture.courseReady ? 'Jetzt beitreten!' : ''}}
            </h6>
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{thisMoment(lecture.date, 'YYYY-MM-DD').format('DD.
                MMM YYYY')}}</h6>
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{lecture.start}} - {{lecture.end}}</h6>
        </div>

        <div id="collapseOne" class="collapse show" *ngIf="lecture.expand">
            <div class="card-body">
                <h6 class="card-title" *ngIf="(lecture.seats - lecture.registrations.length) > 0">{{lecture.seats -
                    lecture.registrations.length}} von
                    {{lecture.seats}} Plätzen noch verfügbar</h6>
                <h6 class="card-title" *ngIf="(lecture.seats - lecture.registrations.length) <= 0">Keine Plätzen mehr
                    verfügbar</h6>
                <p class="card-text" *ngIf="lecture.info">{{lecture.info}}</p>
                <button class="btn btn-sm btn-block btn-outline-success"
                    *ngIf="(lecture.seats - lecture.registrations.length) > 0 && !lecture.registered"
                    (click)="openRegistrationModal(lecture)">Anmelden</button>
                <p *ngIf="lecture.registered" style="text-align: center; font-weight: 100;">Bereits angemeldet</p>
               <!--  <button class="btn btn-sm btn-block btn-success" *ngIf="lecture.registered" [disabled]="!lecture.courseReady"
                    routerLink="/lecture/{{lecture._id}}">Onlineunterricht beitreten {{!lecture.courseReady ? '(Erst 5 Minuten vor Beginn möglich)' : ''}}</button> -->
            </div>
        </div>
    </div>
</div>

<div class="accordion" id="accordionExample" *ngIf="registrationsOnly">
    <div class="card" *ngIf="lectures.length === 0">
        <div class="card-header d-flex justify-content-between">
            <h6 class="m-0 p-0 flex-fill">Keine {{registrationsOnly ? 'Anmeldungen' : 'Termine'}} vorhanden!</h6>
        </div>
    </div>
    <div class="card" *ngFor="let lecture of lectures">
        <div class="card-header d-flex justify-content-between"
            [ngClass]="getColorClass(1, lecture.courseReady)"
            (click)="lecture.expand = lecture.expand ? false : true">
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{lecture.title}}
                {{ lecture.seats - lecture.registrations.length <= 0 ? '(Ausgebucht)' : '' }}
                {{lecture.courseReady ? 'Jetzt beitreten!' : ''}}
            </h6>
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{thisMoment(lecture.date, 'YYYY-MM-DD').format('DD.
                MMM YYYY')}}</h6>
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{lecture.start}} - {{lecture.end}}</h6>
        </div>

        <div id="collapseOne" class="collapse show" *ngIf="lecture.expand">
            <div class="card-body">
                <h6 class="card-title" *ngIf="(lecture.seats - lecture.registrations.length) > 0">{{lecture.seats -
                    lecture.registrations.length}} von
                    {{lecture.seats}} Plätzen noch verfügbar</h6>
                <h6 class="card-title" *ngIf="(lecture.seats - lecture.registrations.length) <= 0">Keine Plätzen mehr
                    verfügbar</h6>
                <p class="card-text" *ngIf="lecture.info">{{lecture.info}}</p>
                <button class="btn btn-sm btn-block btn-success" *ngIf="lecture.registered" [disabled]="!lecture.courseReady"
                    routerLink="/lecture/{{lecture._id}}">Onlineunterricht beitreten {{!lecture.courseReady ? '(Erst 5 Minuten vor Beginn möglich)' : ''}}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" [ngClass]="{'show': showRegistrationModal}" role="dialog"
    [ngStyle]="{'display': showRegistrationModal ? 'block' : 'none', 'background-color': 'rgba(0,0,0,0.5)'}"
    (click)="showRegistrationModal = false;">
    <div class="modal-dialog modal-lg" role="document" (click)="$event.preventDefault();$event.stopPropagation();">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Für Unterricht anmelden</h5>
                <button type="button" class="close" (click)="showRegistrationModal = false;" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Möchtest du dich verbindlich für folgenden Unterricht anmelden?</p>
                <dl class="row">
                    <dt class="col-md-3">Kurs:</dt>
                    <dd class="col-md-12">{{selectedLecture?.title}}</dd>
                    <dt class="col-md-3">Termin:</dt>
                    <dd class="col-md-12">Am {{thisMoment(selectedLecture?.date, 'YYYY-MM-DD').format('DD. MMM YYYY')}}
                        von {{selectedLecture?.start}} bis {{selectedLecture?.end}}
                    </dd>
                </dl>
                <h4>Wichtige Info:</h4>
                <p>Aus gegebenem Anlass müssen wir euch leider folgendes mitteilen:<br>
                In letzter Zeit häuft es sich, dass einige Fahrschüler:innen sich zum Theorieunterricht anmelden, dann aber <b>UNENTSCHULDIGT</b> fernbleiben.<br>
                Dieses Verhalten - einen Unterrichtsplatz zu blockieren - ist anderen Fahrschüler:innen gegenüber unfair, und das dulden wir nicht!</p>
                <p>Deshalb: <br> Wer 2 mal unentschuldigt vom Theorieunterricht fern bleibt, der wird gelöscht!</p>
                <p>Bitte sagt 24 Stunden vorher ab, dann kann ein anderer vielleicht diesen Platz übernehmen.</p>
                <p>Wir hoffen auf euer Verständnis<br>Euer Fahrschul-Team</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary"
                    (click)="showRegistrationModal = false;">Abbrechen</button>
                <button type="button" class="btn btn-outline-success" (click)="registerForLecture()">Verbindlich
                    anmelden</button>
            </div>
        </div>
    </div>
</div>
