<div class="card">
  <div class="card-body">
    <h5 class="card-title">Registration</h5>
    <form [formGroup]="registrationForm">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="firstNameInput">Vorname</label>
          <input type="text" class="form-control" id="firstNameInput" aria-describedby="firstNameHelp" formControlName="firstName">
          <small class="form-text text-danger col-md-12" *ngIf="registrationForm?.get('firstName')?.errors?.required">Vorname benötigt.</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="lastNameInput">Nachname</label>
          <input type="text" class="form-control" id="lastNameInput" aria-describedby="lastNameHelp" formControlName="lastName">
          <small class="form-text text-danger col-md-12" *ngIf="registrationForm?.get('lastName')?.errors?.required">Nachname benötigt.</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="mailInput">Email address</label>
          <input type="email" class="form-control" id="mailInput" aria-describedby="emailHelp" formControlName="mail">
          <small class="form-text text-danger col-md-12" *ngIf="registrationForm?.get('mail')?.errors?.required">E-Mail Adresse benötigt.</small>
          <small class="form-text text-danger col-md-12" *ngIf="registrationForm?.get('mail')?.errors?.email">Keine valide E-Mail Adresse.</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 mb-0">
          <label for="passwordInput">Passwort</label>
          <input type="password" class="form-control" id="passwordInput" formControlName="password" aria-describedby="passwordHelp">
          <small class="form-text text-danger" *ngIf="registrationForm?.get('password')?.errors?.minlength">Das Passwort ist zu kurz (min. 6 Zeichen lang).</small>
        </div>
        <div class="form-group col-md-6 mb-0">
          <label for="passwordConfirmInput">Passwort Wiederholen</label>
          <input type="password" class="form-control" id="passwordConfirmInput" formControlName="passwordConfirm">
        </div>
        <small class="form-text text-danger col-md-12" *ngIf="registrationForm.errors?.pwMissmatch">Die Passwörter stimmen nicht überein.</small>
        <small class="form-text text-muted col-md-12 mb-4">Ein sicheres Passwort besteht aus Groß- und Kleinbuchstaben, Zahlen, Sonderzeichen und hat eine Länge von 8 Zeichen!</small>
      </div>
      <div class="alert alert-danger" role="alert" *ngIf="showErrorMsg">
        {{errorMsg}}
      </div>
      <div class="alert alert-success" role="alert" *ngIf="showSuccessMsg">
        Erfolgreich registriert. Bitte warte, bis dein Account von einem Fahrlehrer freigeschaltet wird!
      </div>
      <button (click)="submitRegistration()" class="btn btn-block btn-outline-success" [disabled]="registrationForm.disabled || registrationForm.invalid" *ngIf="!showSuccessMsg">Registrieren</button>
      <button (click)="goToLogin()" class="btn btn-link btn-block">Zurück zum Login</button>
    </form>
  </div>
</div>
