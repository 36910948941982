<div class="form-row">
    <div class="form-group col-md-6">
        <div class="form-group hidden">
            <div class="input-group">
                <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                    outsideDays="hidden" [startDate]="fromFilter!">
                <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                    </span>
                </ng-template>
            </div>
        </div>
        <div class="input-group">
            <div class="input-group-prepend" style="background-color:rgb(255, 255, 255);">
                <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button">Von:
                </button>
            </div>
            <input #dpfromFilter class="form-control" placeholder="yyyy-mm-dd" name="dpfromFilter"
                (click)="datepicker.toggle()" [value]="formatter.format(fromFilter)"
                (input)="fromFilter = validateInput(fromFilter, dpfromFilter.value)">
        </div>
    </div>
    <div class="form-group col-md-6">
        <div class="input-group">
            <div class="input-group-prepend" style="background-color:rgb(255, 255, 255);">
                <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button">Bis:
                </button>
            </div>
            <input #dptoFilter class="form-control" placeholder="yyyy-mm-dd" name="dptoFilter"
                (click)="datepicker.toggle()" [value]="formatter.format(toFilter)"
                (input)="toFilter = validateInput(toFilter, dptoFilter.value)">
        </div>
    </div>
</div>

<div class="alert alert-success" role="alert" *ngIf="showSuccessMsg">
    Unterricht erfolgreich erstellt!
</div>

<div class="alert alert-success" role="alert" *ngIf="showEditSuccessMsg">
    Unterricht erfolgreich aktualisiert!
</div>

<button class="btn btn-block btn-success" (click)="openNewLectureModal()">Neuen Unterricht anlegen</button>
<br>
<div class="accordion" id="accordionExample">
    <div class="card" *ngIf="lectures.length === 0">
        <div class="card-header d-flex justify-content-between">
            <h6 class="m-0 p-0 flex-fill">Keine Termine vorhanden!</h6>
        </div>
    </div>
    <div class="card" *ngFor="let lecture of lectures">
        <div class="card-header d-flex justify-content-between"
            [ngClass]="getColorClass((lecture.seats - lecture.registrations.length))"
            (click)="lecture.expand = lecture.expand ? false : true">
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{lecture.title}}
                {{ lecture.seats - lecture.registrations.length === 0 ? '(Ausgebucht)' : '' }}</h6>
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{thisMoment(lecture.date, 'YYYY-MM-DD').format('DD.
                MMM YYYY')}}</h6>
            <h6 class="m-0 p-0 flex-fill" style="max-width: 33%;">{{lecture.start}} - {{lecture.end}}</h6>
        </div>

        <div id="collapseOne" class="collapse show" *ngIf="lecture.expand">
            <div class="card-body">
                <h6 class="card-title" *ngIf="(lecture.seats - lecture.registrations.length) > 0">{{lecture.seats -
                    lecture.registrations.length}} von
                    {{lecture.seats}} Plätzen noch verfügbar</h6>
                <h6 class="card-title" *ngIf="(lecture.seats - lecture.registrations.length) <= 0">Keine Plätzen mehr
                    verfügbar</h6>
                <p class="card-text mb-2" *ngIf="lecture.info">{{lecture.info}}</p>
                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-sm btn-block btn-outline-secondary"
                            (click)="openUpdateLectureModal(lecture)">
                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                            Infos Bearbeiten
                        </button>
                    </div>
                    <div class="col-md-6">
                        <a class="btn btn-sm btn-block btn-outline-secondary mb-3"
                            href="mailto:{{buildEmailsString(lecture.registrations)}}">
                            E-Mail an Teilnehmer verschicken!
                    </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-sm btn-block btn-outline-secondary mb-3"
                            (click)="openAffirmationsModal(lecture)">
                            <fa-icon [icon]="['fas', 'user-check']"></fa-icon>
                            Anwesenheitsliste
                        </button>
                    </div>
                    <div class="col-md-6">
                        <a class="btn btn-sm btn-block btn-outline-secondary mb-3"
                            (click)="openAffirmationsModal(lecture)" routerLink="/lecture/{{lecture._id}}">
                            <fa-icon [icon]="['fas', 'phone']"></fa-icon>
                            Online Unterricht beitreten
                        </a>
                    </div>
                </div>
                <p class="card-text text-warning mb-0" *ngIf="lecture.registrations.length === 0">Noch keine Fahrschüler
                    angemeldet</p>
                <ul class="list-group list-group-flush" *ngIf="lecture.registrations.length > 0">
                    <li class="list-group-item list-group-item-dark" style="padding: .3rem 1.25rem;"><b>Angemeldete
                            Fahrschüler</b></li>
                    <li class="list-group-item d-flex justify-content-between align-items-center"
                        style="padding: .25rem 1.25rem;" *ngFor="let reg of lecture.registrations">
                        {{reg.firstName}} {{reg.lastName}}
                        <button class="btn btn-sm btn-outline-danger"
                            title="Anmeldung des Fahrschülers zum Unterricht entfernen"
                            (click)="removeFromLecture(lecture._id,reg._id)">
                            <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" [ngClass]="{'show': showNewLectureModal}" role="dialog"
    [ngStyle]="{'display': showNewLectureModal ? 'block' : 'none', 'background-color': 'rgba(0,0,0,0.5)'}"
    (click)="showNewLectureModal = false;">
    <div class="modal-dialog modal-lg" role="document" (click)="$event.preventDefault();$event.stopPropagation();">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Neuen Termin erstellen</h5>
                <button type="button" class="close" (click)="showNewLectureModal = false;" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form" [formGroup]="newForm">
                    <div class="form-group row">
                        <label for="newTitleInput" class="col-md-3 col-form-label">Titel</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" id="newTitleInput" formControlName="title">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newToInput" class="col-md-3 col-form-label">Anzahl der Plätze</label>
                        <div class="col-md-9">
                            <input type="number" class="form-control" id="newToInput" formControlName="seats">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newInfoInput" class="col-md-3 col-form-label">Info</label>
                        <div class="col-md-9">
                            <textarea class="form-control" id="newInfoInput" formControlName="info"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newDateInput" class="col-md-3 col-form-label">Datum</label>
                        <div class="col-md-9">
                            <!-- <input type="date" class="form-control" id="newDateInput" formControlName="date"> -->
                            <div class="input-group">
                                <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
                                    #d="ngbDatepicker" formControlName="date">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                        Öffnen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newInfoInput" class="col-md-3 col-form-label">Uhrzeit</label>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="newFromInput" class="">Von</label>
                                    <ngb-timepicker formControlName="start"></ngb-timepicker>
                                    <!-- <input type="time" class="form-control" id="newFromInput"> -->
                                </div>
                                <div class="col-md-6">
                                    <label for="newToInput" class="">Bis</label>
                                    <ngb-timepicker formControlName="end"></ngb-timepicker>
                                    <!-- <input type="time" class="form-control" id="newToInput"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary"
                    (click)="showNewLectureModal = false;">Abbrechen</button>
                <button type="button" class="btn btn-success" (click)="saveNewLecture()">Termin anlegen</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" [ngClass]="{'show': showEditLectureModal}" role="dialog"
    [ngStyle]="{'display': showEditLectureModal ? 'block' : 'none', 'background-color': 'rgba(0,0,0,0.5)'}"
    (click)="showEditLectureModal = false;">
    <div class="modal-dialog modal-lg" role="document" (click)="$event.preventDefault();$event.stopPropagation();">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Termin Ändern</h5>
                <button type="button" class="close" (click)="showEditLectureModal = false;" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form" [formGroup]="updateForm">
                    <div class="form-group row">
                        <label for="editTitleInput" class="col-md-3 col-form-label">Titel</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" id="editTitleInput" formControlName="title">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="editToInput" class="col-md-3 col-form-label">Anzahl der Plätze</label>
                        <div class="col-md-9">
                            <input type="number" class="form-control" id="editToInput" formControlName="seats">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="editInfoInput" class="col-md-3 col-form-label">Info</label>
                        <div class="col-md-9">
                            <textarea class="form-control" id="editInfoInput" formControlName="info"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="editDateInput" class="col-md-3 col-form-label">Datum</label>
                        <div class="col-md-9">
                            <!-- <input type="date" class="form-control" id="newDateInput" formControlName="date"> -->
                            <div class="input-group">
                                <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
                                    #d="ngbDatepicker" formControlName="date">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                        Öffnen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="editInfoInput" class="col-md-3 col-form-label">Uhrzeit</label>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="editFromInput" class="">Von</label>
                                    <ngb-timepicker formControlName="start"></ngb-timepicker>
                                    <!-- <input type="time" class="form-control" id="newFromInput"> -->
                                </div>
                                <div class="col-md-6">
                                    <label for="editToInput" class="">Bis</label>
                                    <ngb-timepicker formControlName="end"></ngb-timepicker>
                                    <!-- <input type="time" class="form-control" id="newToInput"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary"
                    (click)="showEditLectureModal = false;">Abbrechen</button>
                <button type="button" class="btn btn-success" (click)="updateLecture()">Upgedatete Infos
                    speichern</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" [ngClass]="{'show': showLecutreAffirmationsModal}" role="dialog"
    [ngStyle]="{'display': showLecutreAffirmationsModal ? 'block' : 'none', 'background-color': 'rgba(0,0,0,0.5)'}"
    (click)="showLecutreAffirmationsModal = false;">
    <div class="modal-dialog modal-lg" role="document" (click)="$event.preventDefault();$event.stopPropagation();">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Anwesenheitsliste</h5>
                <button type="button" class="close" (click)="showLecutreAffirmationsModal = false;" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" id="attendance-print-wrapper">
                <h4>{{selectedLecture?.title}}</h4>
                <h6>Am {{selectedLecture?.date}} von {{selectedLecture?.start}}Uhr bis {{selectedLecture?.end}}Uhr</h6>
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let aff of attendanceList">
                      {{aff.user.firstName}} {{aff.user.lastName}}
                      <span *ngIf="aff.ok" class="badge badge-success badge-pill">
                        Bestätigt
                      </span>
                      <span *ngIf="!aff.ok" class="badge badge-warning badge-pill">
                        Nicht bestätigt
                      </span>
                    </li>
                  </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary"
                    (click)="showLecutreAffirmationsModal = false;">Schließen</button>
                <button type="button" class="btn btn-success" (click)="printAttendanceList()">Liste drucken</button>
            </div>
        </div>
    </div>
</div>