<div class="card">
  <div class="card-body">
    <h5 class="card-title">Benutzerprofil</h5>
    <form [formGroup]="userForm">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="firstNameInput">Vorname</label>
          <input type="text" class="form-control" id="firstNameInput" aria-describedby="firstNameHelp" formControlName="firstName">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="lastNameInput">Nachname</label>
          <input type="text" class="form-control" id="lastNameInput" aria-describedby="lastNameHelp" formControlName="lastName">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="mailInput">Email address</label>
          <input type="email" class="form-control" id="mailInput" aria-describedby="emailHelp" formControlName="mail">
        </div>
      </div>
      <div class="alert alert-danger mt-1" role="alert" *ngIf="userError">{{userErrorMsg}}</div>
      <div class="alert alert-success mt-1" role="alert" *ngIf="userSuccess">{{userSuccessMsg}}</div>
      <button type="submit" class="btn btn-primary" (click)="saveProfile()" [disabled]="userForm.invalid">Benutzerdaten Ändern</button>
    </form>
    <hr>
    <h5 class="card-title">Neue Passwort</h5>
    <form [formGroup]="passwordForm">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="newPasswordInput">Passwort</label>
          <input type="password" class="form-control" id="newPasswordInput" formControlName="newPassword" aria-describedby="newPasswordHelp">
          <small id="newPasswordHelp" class="form-text text-muted">Ein sicheres Passwort besteht aus Groß- und Kleinbuchstaben, Zahlen, Sonderzeichen und hat eine Länge von 8 Zeichen!</small>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="newPasswordConfirmInput">Passwort Wiederholen</label>
          <input type="password" class="form-control" id="newPasswordConfirmInput" formControlName="newPasswordConfirm">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="oldPasswordInput">Aktuelles Passwort</label>
          <input type="password" class="form-control" id="oldPasswordInput" formControlName="currentPassword">
        </div>
      </div>
      <div class="alert alert-danger mt-1" role="alert" *ngIf="pwError">{{pwErrorMsg}}</div>
      <div class="alert alert-success mt-1" role="alert" *ngIf="pwSuccess">{{pwSuccessMsg}}</div>
      <button type="submit" class="btn btn-primary" (click)="savePassword()" [disabled]="passwordForm.invalid">Passwort Ändern</button>
    </form>
  </div>
</div>
