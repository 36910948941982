<div [ngStyle]="{'width': width+'px', 'height': height+'px', 'overflow': 'hidden', 'position': 'relative'}">
    <div class="video-overlay">
        <p>{{user?.firstName}} {{user?.lastName}}</p>
        <fa-icon [styles]="{'margin-left': '5px'}" [size]="'2x'" [icon]="['fas', 'hand-paper']" *ngIf="handRaised"></fa-icon>
        <fa-icon [styles]="{'margin-left': '5px'}" [size]="'2x'" [icon]="['fas', 'microphone']" *ngIf="micEnabled"></fa-icon>
        <fa-icon [styles]="{'margin-left': '5px'}" [size]="'2x'" [icon]="['fas', 'microphone-slash']" *ngIf="!micEnabled"></fa-icon>
    </div>
    <video #videoElement muted [ngClass]="{'hidden': showAvatar, 'video-element': true}"></video>
    <ngx-avatar class="video-element" name="{{user?.firstName}} {{user?.lastName}}" [size]="width"
        [ngStyle]="{'display': 'inline-block'}" [round]="false" *ngIf="showAvatar"></ngx-avatar>    
</div>