<h3>Hi {{userName}}</h3>
<ul class="nav nav-tabs nav-justified">
  <li class="nav-item">
    <a class="nav-link d-flex justify-content-between align-items-center" [ngClass]="{'active': tabs.locked}" (click)="changeTab('locked')">
      Gesperrte Nutzer
      <span class="badge badge-pill" [ngClass]="{'badge-success': lockedUser?.length === 0, 'badge-warning': lockedUser?.length > 0}">{{lockedUser?.length}}</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link d-flex justify-content-between align-items-center" [ngClass]="{'active': tabs.all}" (click)="changeTab('all')">
      Alle Nutzer
      <span class="badge badge-pill badge-secondary">{{allUser?.length}}</span></a>
  </li>
</ul>

<div class="tab-content">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf="tabs.locked">
    <br>
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="customSwitch1">
      <label class="custom-control-label" (click)="allLockedToggleClicked()" for="customSwitch1">Auch alte (mit Registration vor letzter Wocher) anzeigen?</label>
    </div>
    <br>
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <span style="width: 25%;">Name</span>
        <span style="width: 25%;">Mail</span>
        <span style="width: 25%;">Registration</span>
        <span style="width: 25%;">Aktion</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let user of lockedUser">
        <span style="width: 25%;">{{user.firstName}} {{user.lastName}}</span>
        <span style="width: 25%;">{{user.mail}}</span>
        <span style="width: 25%;">{{thisMoment(user.created_at).format('DD. MMM YY HH:mm')}}</span>
        <button class="btn btn-sm btn-outline-primary" style="width: 25%;" (click)="unlockUser(user._id)">Benutzer Entsperren</button>
      </li>
    </ul>
  </div>
  <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab" *ngIf="tabs.all">
    <br>
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <span style="width: 25%;">Name</span>
        <span style="width: 25%;">Mail</span>
        <span style="width: 25%;">Registration</span>
        <span style="width: 25%;">Aktion</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let user of allUser">
        <span style="width: 25%;">{{user.firstName}} {{user.lastName}}</span>
        <span style="width: 25%;">{{user.mail}}</span>
        <span style="width: 25%;">{{thisMoment(user.created_at).format('DD. MMM YY HH:mm')}}</span>
        <button class="btn btn-sm btn-outline-warning" style="width: 25%;" *ngIf="user.unlocked" (click)="lockUser(user._id)">Benutzer Sperren</button>
        <button class="btn btn-sm btn-outline-success" style="width: 25%;" *ngIf="!user.unlocked" (click)="unlockUser(user._id)">Benutzer Entsperren</button>
      </li>
    </ul>
  </div>
</div>
